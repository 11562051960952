import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  itemWrapper: {
    display: 'flex',
    gap: '10px',
    height: '64px',
    width: '100%',
    borderLeft: '2px solid #6C59E6',
    background: '#F7F5FF',
    padding: '10px 12px 10px 16px',
  },
  inputWrapper: {
    width: '100%',
    borderRadius: '10px',
    background: '#FFF',
    display: 'flex',
    gap: '16px',
    '& .MuiFormControl-root, .MuiInputBase-root': {
      height: '100%',
    },
  },
  actions: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '48px',
  },
  action: {
    height: '20px',
    width: '20px',
    '& svg': {
      color: '#6C59E6',
      cursor: 'pointer',
    },
    '& button': {
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      padding: '0',
      background: 'none',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));
