import {
  useCallback, useEffect, useState,
} from 'react';
import { IMarketplace } from 'src/typings/marketplaces';
import { getMarketplaces } from 'src/api/marketplace';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';

export const useMarketplaces = () => {
  const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchMarketplaces = () => {
    setIsLoading(true);
    getMarketplaces(jwt)
      .then((res) => {
        const { data } = res;
        setMarketplaces(data['hydra:member']);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getMarketplace = useCallback((id: number) => {
    return marketplaces.find((marketplace) => marketplace.id === id);
  }, [marketplaces]);

  useEffect(() => {
    fetchMarketplaces();
  }, [jwt]);

  return {
    marketplaces,
    getMarketplace,
    isLoading,
    fetchMarketplaces,
    setMarketplaces,
  };
};
