import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  textField: {
    '& .Mui-disabled': {
      opacity: 0.26,
    },
  },
}));
