import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { IComplex } from 'src/typings/complexes';
import { borderColor } from 'src/constants/styles';
import IconButton from '@mui/material/IconButton';
import { appIcon } from 'src/assets';
import { ComplexModal } from 'src/components/profileComponents/Complexes/components/ComplexModal';
import { useStyles } from '../styles';

export const ComplexImages: React.FC<{complex: IComplex, fetchComplex: () => void}> = ({ complex, fetchComplex }) => {
  const styles = useStyles({});
  const [preview] = useState(complex.previewFileUri?.fileUrl);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box
      border={`1px solid ${borderColor}`}
      borderRadius="8px"
      width={440}
      overflow="hidden"
    >
      <Box
        width="100%"
        height="365px"
        position="relative"
        style={{
          backgroundImage: `url(${preview})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Box className={styles.previewUpload}>
          <Box
            className="edit-icon"
            style={{
              borderRadius: '50%',
              backgroundColor: 'white',
              opacity: preview ? '.5' : '1',
            }}
            onClick={() => setModalOpen(true)}
          >
            <IconButton color="primary" size="large">
              <img src={appIcon.iconEdit.default} alt="edit statuses" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      />
      {modalOpen && (
        <ComplexModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshComplexes={fetchComplex}
          complex={complex}
        />
      )}
    </Box>
  );
};
