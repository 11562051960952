/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { pathList } from 'src/containers/Profile/routeList';
import { translations } from 'src/constants/translations';
import { requestSelectors } from 'src/store/requests/selectors';
import { ButtonApp } from 'src/components/Button';
import { Multiselect } from 'src/components/Multiselect';
import { UploadButton } from 'src/components/UploadButton';
import { IComplex } from 'src/typings/complexes';
import { IUsers, TypeOfRole } from 'src/typings/user';
import { useGetAuthData } from 'src/hooks';
import { uploadFile, patchUsersIdData, postUsersIdData } from 'src/api';
import { borderColor } from 'src/constants/styles';
import { useStyles } from '../styles';

interface IManagerForm {
  isDevType?: boolean;
  isComplexType?: boolean;
  user?: IUsers | null;
  complex?: IComplex | null;
}

const schema = yup.object().shape({
  username: yup.string().required(translations.errorRequired),
  email: yup.string().email(translations.errorInvalidEmail).required(translations.errorRequired),
});

export const ManagerForm: React.FC<IManagerForm> = ({
  isComplexType, isDevType, user, complex,
}) => {
  const { id }: { id: string } = useParams();
  const styles = useStyles({});
  const history = useHistory();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const myselfData = useSelector(requestSelectors.user.getUserData);
  const [icon, setIcon] = useState(user ? user.iconUri : '');
  const [error, setError] = useState('');

  const currentComplex = complex
    ? [{
      value: complex['@id'],
      label: complex.title[complex.language],
    }] : [];
  const currentDev = complex && complex.developers[0]
    ? [{
      value: complex.developers[0]['@id'],
      label: complex.developers[0].name[complex.developers[0].language],
    }] : [];
  const [selectedComplexes, setSelectedComplexes] = useState(user ? user.complexes.map((comp) => {
    return comp['@id'];
  }) : currentComplex.map((comp) => {
    return comp.value;
  }));
  const [selectedDevs, setSelectedDevs] = useState(user ? user.developers.map((dev) => {
    return dev['@id'];
  }) : currentDev.map((dev) => {
    return dev.value;
  }));

  const {
    register, handleSubmit, errors, formState,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleUploadIcon = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('logoUri', event.target.files[0]);

      uploadFile(jwt, formData).then((res: any) => {
        setIcon(res.data.logoUri);
      });
    }
  };

  const onSubmit = async (data: any) => {
    data.complexes = selectedComplexes;
    data.developers = selectedDevs;
    let res;
    if (user) {
      data.complexes = selectedComplexes;
      res = await patchUsersIdData(jwt, user.id, data);
    } else {
      res = await postUsersIdData(jwt, data);
    }
    if (res.ok) {
      history.push(`/profile/${pathList.complexes}/${id}`);
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box fontSize="18px" width="500px">
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.photo}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="166px"
            height="166px"
            border={`1px solid ${borderColor}`}
            borderRadius="50%"
            style={{
              backgroundColor: '#FDFDFD',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${icon})`,
            }}
          >
            <input type="hidden" ref={register} name="iconUri" value={icon || ''} />
            <label htmlFor="userIcon">
              <input
                accept="image/*,.jpg,.jpeg,.png,.svg,.webp"
                style={{
                  display: 'none',
                }}
                id="userIcon"
                type="file"
                onChange={handleUploadIcon}
              />
              <UploadButton isBig withoutBorder />
            </label>

          </Box>
        </Box>
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.name}
          </Box>
          <Box flex="1" position="relative">
            <TextField
              defaultValue={user?.username}
              id="managerName"
              variant="outlined"
              name="username"
              inputRef={register}
            />
            {errors.username && (
            <Box className={styles.error}>
              {errors.username.message}
            </Box>
            )}
          </Box>
        </Box>
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.email}
          </Box>
          <Box flex="1" position="relative">
            <TextField
              defaultValue={user?.email}
              id="managerEmail"
              variant="outlined"
              name="email"
              inputRef={register}
            />
            {errors.email && (
            <Box className={styles.error}>
              {errors.email.message}
            </Box>
            )}
          </Box>
        </Box>
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.role}
          </Box>
          <Box display="flex">
            <input
              ref={register}
              style={{ display: 'none' }}
              type="radio"
              defaultChecked={user?.role?.['@id'] === TypeOfRole.manager}
              id="role_manager"
              name="role"
              value={TypeOfRole.manager}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="role_manager" className="radioBtn">
              {translations.manager}
            </label>
            <input
              ref={register}
              style={{ display: 'none' }}
              type="radio"
              id="role_super"
              defaultChecked={user?.role?.['@id'] !== TypeOfRole.manager}
              name="role"
              value={TypeOfRole.supervisor}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="role_super" className="radioBtn">
              {translations.supervisor}
              ★
            </label>
          </Box>
        </Box>
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.project}
          </Box>
          <Box flex="1">
            <Multiselect
              isDisabled={isComplexType}
              placeholder={`${translations.addProject}...`}
              options={myselfData?.complexes.map((comp) => {
                return {
                  value: comp['@id'],
                  label: comp.title[comp.language],
                };
              })}
              defaultValue={user ? user.complexes.map((comp) => {
                return {
                  value: comp['@id'],
                  label: comp.title[comp.language],
                };
              }) : currentComplex}
            />
          </Box>
        </Box>
        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2}>
            {translations.developer}
          </Box>
          <Box flex="1">
            <Multiselect
              isDisabled={isDevType}
              placeholder={`${translations.addDeveloper}...`}
              options={myselfData?.developers.map((dev) => {
                return {
                  value: dev['@id'],
                  label: dev.name[dev.language],
                };
              })}
              defaultValue={user ? user.developers.map((dev) => {
                return {
                  value: dev['@id'],
                  label: dev.name[dev.language],
                };
              }) : currentDev}
            />
          </Box>
        </Box>

        <Box mb={6} display="flex" alignItems="center">
          <Box width="130px" mr={2} />
          <Box width="256px">
            <ButtonApp
              type="submit"
              color="primary"
              isCapitalize
              variant="contained"
              disabled={!formState.isValid}
              text={user ? translations.saveChanges : translations.addManager}
            />
          </Box>
        </Box>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </form>
  );
};
