import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function uploadFile(token: string = '', formData: FormData) {
  return httpService.post(`${ApiUrl.getApiUrl().uploadFile}`, formData, {
    Authorization: `Bearer ${token}`,
  });
}

export function importPlacements(token: string = '', id: string | number, formdata: FormData) {
  return httpService.post(`${ApiUrl.getApiUrl().importPlacements}/house/${id}`, formdata, {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  });
}

export function exportPlacements(token: string = '', id: string | number) {
  return httpService.get(`${ApiUrl.getApiUrl().exportPlacements}/house/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

function getCurrentDateTime() {
  const date = new Date();
  const year = date.getFullYear().toString().substr(-2).padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const dateTimeString = `${day}.${month}.${year}_${hours}-${minutes}-${seconds}`;
  return dateTimeString;
}

export function uploadPlacementXlsxByHouse(houseId: string | number, houseNumeration: string | number, complexName: string, token: string = '') {
  fetch(`${ApiUrl.getApiUrl().exportPlacements}/house/${houseId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response: any) => {
    // Check if the response is successful
    if (response.ok) {
      // Extract the filename from the Content-Disposition header
      const date = getCurrentDateTime();
      const filename = `Placement_${complexName}_${houseNumeration}_${date}.xlsx`;
      // Convert the response to a blob object
      return response.blob().then((blob: any) => {
        // Create a temporary anchor element to download the blob
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      });
    }
    throw new Error('Network response was not ok');
  })
    .catch((error: any) => {
      console.error('Error:', error);
    });
}

export function uploadPlacementXlsxByHouseHistory(
  houseId: string | number,
  houseNumeration: string | number,
  complexName: string,
  token: string = '',
) {
  fetch(`${ApiUrl.getApiUrl().exportPlacements}/house_excel_history/${houseId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response: any) => {
    // Check if the response is successful
    if (response.ok) {
      // Extract the filename from the Content-Disposition header
      const date = getCurrentDateTime();
      const filename = `Placement_${complexName}_${houseNumeration}_${date}.xlsx`;
      // Convert the response to a blob object
      return response.blob().then((blob: any) => {
        // Create a temporary anchor element to download the blob
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      });
    }
    throw new Error('Network response was not ok');
  })
    .catch((error: any) => {
      console.error('Error:', error);
    });
}
