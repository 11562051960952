/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Loader } from 'src/components/Loader';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDevelopers, useGetAuthData } from 'src/hooks';
import { IDeveloper } from 'src/typings/complexes';
import { borderColor } from 'src/constants/styles';
import { deleteDeveloperData } from 'src/api';
import { SearchField } from 'src/components/SearchField';
import { ButtonApp } from 'src/components/Button';
import { TypeOfRole } from 'src/typings/user';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { appIcon } from 'src/assets';
import { useStyles } from './styles';
import { DevModal } from './components/DevModal';

export const Developers = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [editedDev, setEditedDev] = useState<IDeveloper|null>(null);
  const styles = useStyles({});
  const { isLoading, developers, fetchDevelopers } = useGetDevelopers();
  const [developersList, setDevelopersList] = useState<IDeveloper[]>(developers);
  const user = useSelector(requestSelectors.user.getUserData);

  useEffect(() => {
    setDevelopersList(developers);
  }, [developers]);

  const goToDeveloper = (id: string|number) => {
    history.push(`/profile/${pathList.developers}/${id}`);
  };

  const handleAddDev = () => {
    setEditedDev(null);
    setModalOpen(true);
  };

  const handleChangeDeveloper = useCallback((searchTerm: string) => {
    const search = searchTerm.toLowerCase();
    if (search) {
      setDevelopersList(developers.filter((dev) => {
        return dev.name[dev.language].toLowerCase().indexOf(search) >= 0;
      }));
    } else {
      setDevelopersList(developers);
    }
  }, [developers]);

  const editDeveloper = useCallback((developer: IDeveloper) => {
    setEditedDev(developer);
    setModalOpen(true);
  }, []);

  if (isLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box py={8} px={4} height="100%">
      <Box mb={8} display="flex" flexWrap="nowrap">
        <Box fontSize="32px" mr="24px" fontWeight={700}>
          { translations.developersLink }
        </Box>
        <Box width={340} mr="auto">
          <SearchField
            handleChange={handleChangeDeveloper}
            placeholder={translations.developer}
          />
        </Box>
        <Box ml="24px">
          <ButtonApp
            text={translations.addDeveloper}
            onClick={handleAddDev}
            color="primary"
            variant="contained"
            type="submit"
            className={styles.btn}
          />
        </Box>
      </Box>
      {developersList.length ? (
        <Grid container spacing={2}>
          { developersList.map((developer) => (
            <Grid item xs={2} key={developer['@id']}>
              <Box
                className={styles.developerHover}
                border={`1px solid ${borderColor}`}
                borderRadius="3px"
                p={2}
                key={developer['@id']}
                display="flex"
                flexDirection="column"
                alignItems="center"
                height="100%"
              >
                <Box
                  width="100px"
                  height="100px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  onClick={() => goToDeveloper(developer.id)}
                >
                  <img
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                    src={developer.logoFileUri?.fileUrl}
                    alt={developer.name[developer.language]}
                  />
                </Box>
                {user?.role?.['@id'] === TypeOfRole.manager ? null : (
                  <IconButton
                    color="primary"
                    aria-label="edit button"
                    component="div"
                    className={styles.editBtn}
                    onClick={() => editDeveloper(developer)}
                    size="large"
                  >
                    <img src={appIcon.iconEdit.default} alt="edit statuses" />
                  </IconButton>
                )}
                {developer.name[developer.language]}
              </Box>
            </Grid>
          )) }
        </Grid>
      ) : (
        <Box display="flex" justifyContent="center">
          {translations.notFoundDevelopers}
        </Box>
      )}
      {modalOpen && (
        <DevModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshDevelopers={fetchDevelopers}
          developer={editedDev}
        />
      )}
    </Box>
  );
};
