/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDeveloper } from 'src/hooks';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { toUpperCase } from 'src/helpers/toUpperCase';
import { useGetProject } from 'src/components/profileComponents/Integrations/Bitrix/hooks/useGetProject';
import { MainDataForm } from 'src/components/profileComponents/Integrations/Bitrix/components/MainDataForm';
import {
  patchBitrixProject,
  patchNetHuntProject,
  postBitrixProject,
  postNetHuntProject,
} from 'src/api';
import { BitrixIntegration } from 'src/components/profileComponents/Integrations/Bitrix';
import { CreatioIntegration } from 'src/components/profileComponents/Integrations/Creatio';
import { NetHuntIntegration } from 'src/components/profileComponents/Integrations/Nethunt';
import { projectType } from 'src/components/profileComponents/Integrations/Nethunt/types';
import { UrlDecoder } from 'src/components/profileComponents/Integrations/Nethunt/components/UrlDecode';

export interface IIntegration {
    integration: IntegrationType;
}

export const Integrations: React.FC<IIntegration> = ({ integration }) => {
  const { id }: { id: string } = useParams();
  const currentIntegrationName = toUpperCase(integration) as IntegrationType;
  const { isLoading, developer } = useGetDeveloper(id);
  const { project, isLoading: isLoadingProject, fetchProject } = useGetProject(developer && developer[projectType[integration]]);

  if (isLoading || isLoadingProject || !developer) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const developerName = developer.name[developer.language];

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Developer ${developerName}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: `${currentIntegrationName} Integration`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`${currentIntegrationName} Integration | ${developerName}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <>
        {integration === CRMNames.creatio && (
          <>
            <Box mb={4}>
              <MainDataForm
                developer={developer}
                project={project}
                fetchProject={fetchProject}
                integration={integration}
                updateFormData={patchBitrixProject}
                createFormData={postBitrixProject}
              />
            </Box>
            {project && (
              <CreatioIntegration integration={integration} project={project} developer={developer} />
            )}
          </>
        )}
        {integration === CRMNames.bitrix && (
          <>
            <Box mb={4}>
              <MainDataForm
                developer={developer}
                project={project}
                fetchProject={fetchProject}
                integration={integration}
                updateFormData={patchBitrixProject}
                createFormData={postBitrixProject}
              />
            </Box>
            {project && (
              <BitrixIntegration integration={integration} project={project} fetchProject={fetchProject} />
            )}
          </>
        )}
        {integration === CRMNames.netHunt && (
          <>
            <Box mb={12}>
              <UrlDecoder />
            </Box>
            <Box mb={12}>
              <MainDataForm
                developer={developer}
                project={project}
                fetchProject={fetchProject}
                integration={integration}
                updateFormData={patchNetHuntProject}
                createFormData={postNetHuntProject}
              />
            </Box>
            <Box mb={12}>
              {project?.workspaceId && project?.authKey && project?.authEmail && (
                <NetHuntIntegration
                  integration={integration}
                  project={project}
                  fetchProject={fetchProject}
                />
              )}
            </Box>
          </>
        )}
      </>
    </Box>
  );
};
