/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Modal, Backdrop,
  Fade, Paper,
  Snackbar, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import {
  IComplex, IFileUri,
} from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import {
  postComplexData, uploadFile, putComplexData,
} from 'src/api';
import Tab from '@mui/material/Tab';
import { SvgUpload } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgUpload';
import { getFileWindow } from 'src/helpers/upload';
import InputAdornment from '@mui/material/InputAdornment';
import { urlToBase64 } from 'src/helpers/urlToBase64';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLanguage } from 'src/hooks/app/use-language';
import { useCountries } from 'src/hooks/app/use-countries';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { LocationDetails } from 'src/components/profileComponents/Complexes/components/LocationDetails';
import { getComplexLocationsDefaultValues } from 'src/helpers/complexEdit';
import { SvgTrashBig } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrashBig';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { SelectChangeEvent } from '@mui/material/Select';
import { useStyles } from '../styles';

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshComplexes: Function;
  complex?: IComplex;
}

const schema = yup.object().shape({
  projectName: yup.string().required(translations.requiredField),
  projectShortName: yup.string().required(translations.requiredField),
  projectAddress: yup.string().required(translations.requiredField),
  systemPrimaryLanguage: yup.string().required(translations.requiredField),
  projectCallBackCountryCode: yup.string(),
  projectLogo: yup.string(),
  projectPreviewImage: yup.string(),
});

export const ComplexModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, complex, refreshComplexes,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const jwt = useGetAuthData().getJWT();

  const {
    register, handleSubmit, setValue, reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [projectName, setProjectName] = useState<string>('');
  const [projectShortName, setProjectShortName] = useState<string>('');
  const [projectAddress, setProjectAddress] = useState<string>('');
  const [systemPrimaryLanguage, _setSystemPrimaryLanguage] = useState<IDevelopmentSpecificationPropChange<string> | undefined>();
  const { languagesCodesOptions: allLanguagesOptions } = useLanguage();
  const [systemSecondaryLanguagesOptions, _setSystemSecondaryLanguagesOptions] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const [systemSecondaryLanguages, setSystemSecondaryLanguages] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const [projectCallBackCountryCode, setProjectCallBackCountryCode] = useState<IDevelopmentSpecificationPropChange<string> | undefined>();
  const [tabValue, setTabValue] = useState<string>('1');
  const [preview, setPreview] = useState<string | null>(null);
  const [previewUri, setPreviewUri] = useState<IFileUri | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [logoUri, setLogoUri] = useState<IFileUri | null>(null);
  const { countriesCodesOptions } = useCountries();

  useEffect(() => {
    if (allLanguagesOptions.length) {
      _setSystemSecondaryLanguagesOptions(allLanguagesOptions);
      _setAdminPanelSecondaryLanguagesOptions(allLanguagesOptions);
      _setWebsiteSecondaryLanguagesOptions(allLanguagesOptions);
    }
  }, [allLanguagesOptions]);

  const setSystemPrimaryLanguage = (currLanguage: string | null) => {
    _setSystemPrimaryLanguage(currLanguage ? { label: currLanguage, value: currLanguage } : undefined);
    _setSystemSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language.value !== currLanguage));
    setSystemSecondaryLanguages(complex?.secondaryLanguages?.map((item) => ({ label: item, value: item })) || []);
  };

  const [adminPanelPrimaryLanguage, _setAdminPanelPrimaryLanguage] = useState<IDevelopmentSpecificationPropChange<string> | undefined>();
  const [adminPanelSecondaryLanguagesOptions, _setAdminPanelSecondaryLanguagesOptions] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const [adminPanelSecondaryLanguages, setAdminPanelSecondaryLanguages] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);

  const setAdminPanelPrimaryLanguage = (currLanguage: string | null) => {
    _setAdminPanelPrimaryLanguage(currLanguage ? { label: currLanguage, value: currLanguage } : undefined);
    _setAdminPanelSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language.value !== currLanguage));
    setAdminPanelSecondaryLanguages(complex?.crosstableSecondaryLanguages?.map((item) => ({ label: item, value: item })) || []);
  };

  const [websitePrimaryLanguage, _setWebsitePrimaryLanguage] = useState<IDevelopmentSpecificationPropChange<string> | undefined>();
  const [websiteSecondaryLanguagesOptions, _setWebsiteSecondaryLanguagesOptions] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const [websiteSecondaryLanguages, setWebsiteSecondaryLanguages] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);

  const setWebsitePrimaryLanguage = (currLanguage: string | null) => {
    _setWebsitePrimaryLanguage(currLanguage ? { label: currLanguage, value: currLanguage } : undefined);
    _setWebsiteSecondaryLanguagesOptions(allLanguagesOptions.filter((language) => language.value !== currLanguage));
    setWebsiteSecondaryLanguages(complex?.moduleSecondaryLanguages?.map((item) => ({ label: item, value: item })) || []);
  };

  useEffect(() => {
    if (complex) {
      const formData = {
        projectName: complex.title[complex.language],
        projectShortName: complex.complexShortName,
        projectAddress: complex.address[complex.language],
        systemPrimaryLanguage: complex.language,
      };
      setProjectName(complex.title[complex.language]);
      setProjectShortName(complex.complexShortName);
      setProjectAddress(complex.address[complex.language]);
      setProjectCallBackCountryCode({ label: complex.clientModuleConfig.formDefaultCountry, value: complex.clientModuleConfig.formDefaultCountry });

      setSystemPrimaryLanguage(complex.language);
      setAdminPanelPrimaryLanguage(complex.crosstableLanguage);
      setWebsitePrimaryLanguage(complex.moduleLanguage);

      urlToBase64(complex.previewFileUri?.fileUrl || '')
        .then((previewUriData) => {
          setPreview(`url("${String(previewUriData.base64Url)}")`);
        });
      urlToBase64(complex.logoFileUri?.fileUrl || '')
        .then((previewUriData) => {
          setLogo(`url("${String(previewUriData.base64Url)}")`);
        });
      reset(formData);
    }
  }, [complex]);

  const onSubmit = async (formData:any) => {
    const saveData: Partial<IComplex> = {
      title: { [String(systemPrimaryLanguage?.value)]: projectName },
      complexShortName: projectShortName,
      address: { [String(systemPrimaryLanguage?.value)]: projectAddress },
      secondaryLanguages: systemSecondaryLanguages.map((language) => language.value),
      crosstableLanguage: adminPanelPrimaryLanguage?.value || null,
      crosstableSecondaryLanguages: adminPanelSecondaryLanguages.length ? adminPanelSecondaryLanguages.map((language) => language.value) : null,
      moduleLanguage: websitePrimaryLanguage?.value || null,
      moduleSecondaryLanguages: websiteSecondaryLanguages.length ? websiteSecondaryLanguages.map((language) => language.value) : null,
      developmentSpecification: complex?.developmentSpecification,
      location: complex?.location,
    };
    if (formData.developmentSpecification) {
      Object.keys(formData.developmentSpecification).forEach((key) => {
        if (key && formData.developmentSpecification[key] && saveData.developmentSpecification) {
          // @ts-ignore
          saveData.developmentSpecification[key] = (
            formData.developmentSpecification[key]
            && Array.isArray(formData.developmentSpecification[key])
            && !formData.developmentSpecification[key].length
          ) ? [] : [formData.developmentSpecification[key]];
        }
      });
    }
    if (formData.location) {
      Object.keys(formData.location).forEach((key) => {
        // @ts-ignore
        saveData.location[key] = formData.location[key] ? { [systemPrimaryLanguage?.value]: formData.location[key] } : null;
      });
    }
    if (systemPrimaryLanguage?.value) {
      saveData.language = systemPrimaryLanguage.value;
    }
    if (previewUri) {
      saveData.previewFileUri = previewUri;
    }
    if (logoUri) {
      saveData.logoFileUri = logoUri;
    }
    if (complex && projectCallBackCountryCode) {
      saveData.clientModuleConfig = {
        ...complex.clientModuleConfig,
        formDefaultCountry: projectCallBackCountryCode.value,
      };
    }

    let res;

    if (complex) {
      res = await putComplexData(jwt, complex.id, saveData);
    } else {
      res = await postComplexData(jwt, saveData);
    }

    if (res.ok) {
      handleChangeOpen(false);
      refreshComplexes();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  // const specificationsDefaultValues = useMemo(() => getComplexSpecificationsDefaultValues(complex), [complex]);

  const locationsDefaultValues = useMemo(() => getComplexLocationsDefaultValues(complex), [complex]);

  // const developerPropertyChange = (data: IDevelopmentSpecificationProp, prop: string) => {
  //   setValue(prop, data);
  // };

  const developerLocationChange = (data: string, prop: string) => {
    setValue(prop, data);
  };

  const close = () => handleChangeOpen(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const readURL = (file: File) => {
    return new Promise<string>((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(String(e?.target?.result));
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const onUpload = useCallback((fileTypeSetter: React.Dispatch<React.SetStateAction<IFileUri | null>>, setter: React.Dispatch<React.SetStateAction<string | null>>) => {
    getFileWindow((file) => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadFile(jwt, formData).then(async (res: any) => {
          if (file && res.data[0]) {
            fileTypeSetter(res.data[0]);
            const base64: string = await readURL(file);
            if (base64) {
              setter(`url("${base64}")`);
            }
          }
        });
      }
    }, { accept: 'image/*,.jpg,.jpeg,.png,.svg,.webp' });
  }, [complex]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="800px" maxHeight="90vh" overflow="auto" p="52px">
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {complex ? translations.projectEditing : translations.createProject}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="MAIN INFORMATION" value="1" />
                        {/* <Tab label="Project specification" value="2" /> */}
                        <Tab label="Location details" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box flex="1" display="flex" gap="40px">
                        <BoxFormTextField
                          label={translations.projectName}
                          name="projectName"
                          inputRef={register}
                          value={projectName}
                          onChange={(event: SelectChangeEvent) => {
                            setProjectName(event.target.value);
                          }}
                          {...register('projectName')}
                        />
                        <BoxFormTextField
                          startAdornment={<InputAdornment position="start">flatshow.property/</InputAdornment>}
                          label={translations.projectShortName}
                          name="projectShortName"
                          inputRef={register}
                          value={projectShortName}
                          onChange={(event: SelectChangeEvent) => {
                            setProjectShortName(event.target.value);
                          }}
                          {...register('projectShortName')}
                        />
                      </Box>

                      <Box flex="1" display="flex" gap="40px">
                        <Box width="60%">
                          <BoxFormTextField
                            label={translations.projectAddress}
                            name="projectAddress"
                            inputRef={register}
                            value={projectAddress}
                            onChange={(event: SelectChangeEvent) => {
                              setProjectAddress(event.target.value);
                            }}
                            {...register('projectAddress')}
                          />
                        </Box>
                        <Box width="40%">
                          <BoxFormAutocomplete
                            label={translations.projectCallBackCountryCode}
                            name="projectCallBackCountryCode"
                            options={countriesCodesOptions}
                            value={projectCallBackCountryCode}
                            onChange={(value: IDevelopmentSpecificationPropChange<string>) => {
                              setProjectCallBackCountryCode(value);
                            }}
                            {...register('projectCallBackCountryCode')}
                          />

                        </Box>
                      </Box>

                      <Box flex="1" display="flex" gap="40px">
                        <BoxFormAutocomplete
                          label={translations.systemPrimaryLanguage}
                          name="systemPrimaryLanguage"
                          options={allLanguagesOptions}
                          value={systemPrimaryLanguage}
                          onChange={(value: IDevelopmentSpecificationPropChange<string>) => {
                            setValue('systemPrimaryLanguage', value.value);
                            setSystemPrimaryLanguage(value.value);
                          }}
                          {...register('systemPrimaryLanguage')}
                        />
                        <BoxFormAutocomplete
                          label={translations.systemSecondaryLanguages}
                          name="systemSecondaryLanguages"
                          options={systemSecondaryLanguagesOptions}
                          value={systemSecondaryLanguages}
                          onChange={setSystemSecondaryLanguages}
                          {...register('systemSecondaryLanguages')}
                        />
                      </Box>

                      <Box flex="1" display="flex" gap="40px">
                        <BoxFormAutocomplete
                          label={translations.adminPanelPrimaryLanguage}
                          name="adminPanelPrimaryLanguage"
                          options={allLanguagesOptions}
                          value={adminPanelPrimaryLanguage}
                          onChange={(value: IDevelopmentSpecificationPropChange<string>) => {
                            setAdminPanelPrimaryLanguage(value.value);
                          }}
                          {...register('adminPanelPrimaryLanguage')}
                        />
                        <BoxFormAutocomplete
                          label={translations.adminPanelSecondaryLanguages}
                          name="adminPanelSecondaryLanguages"
                          options={adminPanelSecondaryLanguagesOptions}
                          value={adminPanelSecondaryLanguages}
                          onChange={(value: IDevelopmentSpecificationPropChange<string>[]) => {
                            setAdminPanelSecondaryLanguages(value);
                          }}
                          {...register('adminPanelSecondaryLanguages')}
                        />
                      </Box>

                      <Box flex="1" display="flex" gap="40px">
                        <BoxFormAutocomplete
                          label={translations.websitePrimaryLanguage}
                          name="websitePrimaryLanguage"
                          options={allLanguagesOptions}
                          value={websitePrimaryLanguage}
                          onChange={(value: IDevelopmentSpecificationPropChange<string>) => {
                            setWebsitePrimaryLanguage(value.value);
                          }}
                          {...register('websitePrimaryLanguage')}
                        />
                        <BoxFormAutocomplete
                          label={translations.websiteSecondaryLanguages}
                          name="websiteSecondaryLanguages"
                          options={websiteSecondaryLanguagesOptions}
                          value={websiteSecondaryLanguages}
                          onChange={(value: IDevelopmentSpecificationPropChange<string>[]) => {
                            setWebsiteSecondaryLanguages(value);
                          }}
                          {...register('websiteSecondaryLanguages')}
                        />
                      </Box>

                      <Box flex="1" display="flex" gap="40px">
                        <div className={styles.uploadWrapper}>
                          <div
                            className={styles.upload}
                          >
                            <div>{ translations.uploadProjectPreviewImage }</div>
                            <Box
                              className={styles.uploadContent}
                            >
                              <BoxFormTextField
                                name="projectPreviewImage"
                                inputRef={register}
                                props={{
                                  disabled: true,
                                  style: {
                                    backgroundImage: `${preview || ''}`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    borderRadius: '4px',
                                  },
                                }}
                                {...register('projectPreviewImage')}
                              />
                              <div className={styles.uploadIconWrapper}>
                                { !preview && (
                                  <Box
                                    className={`${styles.uploadIcon} uploadIcon`}
                                    style={{
                                      opacity: preview ? '.5' : '1',
                                    }}
                                    onClick={() => onUpload(setPreviewUri, setPreview)}
                                  >
                                    <SvgUpload />
                                  </Box>
                                ) }
                                { preview && (
                                  <Box
                                    className={`${styles.uploadIcon} uploadIcon`}
                                    style={{
                                      opacity: preview ? '.5' : '1',
                                    }}
                                    onClick={() => setPreview(null)}
                                  >
                                    <SvgTrashBig />
                                  </Box>
                                ) }
                              </div>
                            </Box>
                          </div>
                        </div>
                        <div className={styles.uploadWrapper}>
                          <Box
                            className={styles.upload}
                          >
                            <div>{ translations.uploadProjectLogo }</div>
                            <Box
                              className={styles.uploadContent}
                            >
                              <BoxFormTextField
                                name="projectLogo"
                                inputRef={register}
                                props={{
                                  disabled: true,
                                  style: {
                                    backgroundImage: `${logo || ''}`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    borderRadius: '4px',
                                  },
                                }}
                                {...register('projectLogo')}
                              />
                              <div className={styles.uploadIconWrapper}>
                                { !logo && (
                                  <Box
                                    className={`${styles.uploadIcon} uploadIcon`}
                                    style={{
                                      opacity: logo ? '.5' : '1',
                                    }}
                                    onClick={() => onUpload(setLogoUri, setLogo)}
                                  >
                                    <SvgUpload />
                                  </Box>
                                ) }
                                { logo && (
                                  <Box
                                    className={`${styles.uploadIcon} uploadIcon`}
                                    style={{
                                      opacity: logo ? '.5' : '1',
                                    }}
                                    onClick={() => setLogo(null)}
                                  >
                                    <SvgTrashBig />
                                  </Box>
                                ) }
                              </div>
                            </Box>
                          </Box>
                        </div>
                      </Box>

                    </TabPanel>
                    {/* <TabPanel value="2"> */}
                    {/*  <ProjectSpecification */}
                    {/*    complex={complex} */}
                    {/*    defaultValues={specificationsDefaultValues} */}
                    {/*    register={register} */}
                    {/*    onChange={(data, prop) => developerPropertyChange(data, prop)} */}
                    {/*  /> */}
                    {/* </TabPanel> */}
                    <TabPanel value="2">
                      <LocationDetails
                        complex={complex}
                        defaultValues={locationsDefaultValues}
                        register={register}
                        onChange={(data, prop) => developerLocationChange(data, prop)}
                      />
                    </TabPanel>
                    <Box display="flex" justifyContent="flex-end" gap="20px">
                      <ButtonApp
                        onClick={close}
                        text={translations.cancel}
                        color="primary"
                        variant="outlined"
                        type="button"
                        className={styles.btn}
                      />
                      <ButtonApp
                        text={translations.saveChanges}
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={styles.btn}
                      />
                    </Box>
                  </TabContext>
                </form>
              </Box>

              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  { error }
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
