import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getHousesByComplex(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouses}?complex=${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getHouseById(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouses}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function putHouseData(token: string = '', id: number, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getHouses}/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getHouseHistory(token: string = '', id: string, page: number = 1) {
  return httpService.get(`${ApiUrl.getApiUrl().getHouseHistory}${id}&itemsPerPage=5&page=${page}&order[createdAt]=desc`, {}, {
    Authorization: `Bearer ${token}`,
  });
}
