import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  logoUpload: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  previewUpload: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
    '& .edit-icon': {
      '&:hover': {
        opacity: '1!important',
      },
    },
  },

  managerControl: {
    padding: '10px 20px',
  },
  deleteManager: {
    border: '1px solid #E66159',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    justifyContent: 'center',
    // eslint-disable-next-line max-len
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  cover: {
    position: 'fixed',
    zIndex: -1,
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    cursor: 'auto',
  },
  colorPicker: {
    cursor: 'pointer !important',

    '& input': {
      cursor: 'pointer !important',
    },
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  closeBtn: {
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
}));
