import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { requestSelectors } from 'src/store/requests/selectors';
import { IComplex } from 'src/typings/complexes';
import { IUsers } from 'src/typings/user';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetComplex, useGetUsers, useSetDocumentTitle } from 'src/hooks';

import { ManagerForm } from './components/ManagerForm';
import { AddExistingManager } from './components/AddExistingManager';

export enum TypeOfPage {
  add = 'add',
  edit = 'edit',
  addWithComplex = 'addWithComplex',
  editWithComplex = 'editWithComplex',
}
interface IManager {
  // eslint-disable-next-line react/require-default-props
  type?: string;
}

export const Manager: React.FC<IManager> = ({ type }) => {
  const { id, userId }: { id: string, userId: string } = useParams();
  const { complex } = useGetComplex(id);
  const user: IUsers|null = useGetUsers(userId);
  const myselfData = useSelector(requestSelectors.user.getUserData);
  const isComplexPage = type === TypeOfPage.addWithComplex || type === TypeOfPage.editWithComplex;
  const isTypeAdd = type === TypeOfPage.addWithComplex || type === TypeOfPage.add;
  const pageTitle = (type === TypeOfPage.addWithComplex || type === TypeOfPage.add)
    ? translations.addingManager
    : translations.editManager;
  let crumbs: ICrumbs[] = [];

  useSetDocumentTitle(pageTitle);
  if (
    (isComplexPage && !complex) || (!isTypeAdd && !user) || !myselfData
  ) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  if (isComplexPage && complex) {
    const {
      language,
      title,
      id: complexId,
    } = complex;

    crumbs = [
      {
        name: translations.complexesLink,
        url: `/profile/${pathList.complexes}`,
      },
      {
        name: `${translations.settings} ${title[language]}`,
        url: `/profile/${pathList.complexes}/${complexId}`,
      },
      {
        name: pageTitle,
      },
    ];
  }

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {pageTitle}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box display="flex">
        <Box mr={4}>
          <ManagerForm isComplexType isDevType complex={complex} user={user} />
        </Box>
        {(isComplexPage && complex) && (
          <AddExistingManager complex={complex} />
        )}
      </Box>
    </Box>
  );
};
