import React from 'react';
import { appIcon } from 'src/assets';
import { DragConfig } from './types';

export const Drag: React.FC<{config?: DragConfig}> = ({ config }) => {
  return (
    <button type="button" className="drag-handle" style={{ cursor: 'grab' }}>
      <img src={config ? config.dragSrc : appIcon.iconDrag} alt="iconDrug" />
    </button>
  );
};
