import { IComplex, IDevelopmentSpecification, IStatus } from 'src/typings/complexes';
import { IDogeFields } from 'src/typings/global-config';
import { IAction } from 'src/typings';
import { ActionTypes } from './actions';
import { IResponsibleUser } from '../../typings/filter';

export interface IHouse extends IDogeFields {
  id: number;
  numeration: {
    [key: string]: string;
  };
  complex: IComplex;
  developmentSpecification: IDevelopmentSpecification
}

export interface ICreatioDeal extends IDogeFields {
  creatioProject: string;
  creatioStage: string;
  id: number;
  externalId: string;
}

export interface INetHuntDeal extends IDogeFields {
  responsibleUser: IResponsibleUser;
  stage: string;
  funnel: string;
  externalName: string;
  id: number;
  externalId: string;
  netHuntLink: string;
  placements: string[];
}

export interface IBitrixDeal extends IDogeFields {
  bitrixCategory: string;
  bitrixDealClient: string|null;
  bitrixExternalDealId: string;
  bitrixProject: string;
  bitrixStage: string;
  id: number;
  placement: any;
  responsibleUser: any;
}

export interface IHouses {
  complexId: number|string;
  data: IHouse[];
}

export interface ICrosstable {
  complex: IComplex | null;
  statuses: IStatus[] | null;
  houses: IHouses | null;
  availableComplexes: IComplex[] | null;
  bitrixDeal: IBitrixDeal | null;
  creatioDeal: ICreatioDeal | null;
  netHuntDeal: INetHuntDeal | null;
  defaultPlacementType: string | null;
}

export const initialState: ICrosstable = {
  complex: null,
  statuses: null,
  houses: null,
  availableComplexes: null,
  bitrixDeal: null,
  creatioDeal: null,
  netHuntDeal: null,
  defaultPlacementType: null,
};

export const crosstableReducer = (
  state = initialState,
  { type, payload }: IAction,
): ICrosstable => {
  switch (type) {
    case ActionTypes.SET_COMPLEX:
      return {
        ...state,
        complex: payload,
      };
    case ActionTypes.SET_STATUSES:
      return {
        ...state,
        statuses: payload,
      };
    case ActionTypes.SET_HOUSES:
      return {
        ...state,
        houses: payload,
      };
    case ActionTypes.SET_AVAILABLE_COMPLEXES:
      return {
        ...state,
        availableComplexes: payload,
      };
    case ActionTypes.SET_BITRIX_DEAL:
      return {
        ...state,
        bitrixDeal: payload,
      };
    case ActionTypes.SET_CREATIO_DEAL:
      return {
        ...state,
        creatioDeal: payload,
      };
    case ActionTypes.SET_NETHUNT_DEAL:
      return {
        ...state,
        netHuntDeal: payload,
      };
    case ActionTypes.SET_DEFAULT_PLACEMENT_TYPE:
      return {
        ...state,
        defaultPlacementType: payload,
      };

    default:
      return state;
  }
};
