import React, { useEffect } from 'react';
import { translations } from 'src/constants/translations';
import { Box } from '@mui/material';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  IComplex, IDevelopmentSpecificationProp,
} from 'src/typings/complexes';
import { useGetDeveloperSpecifications } from 'src/hooks/useGetDeveloperSpecifications';
import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';

export const ProjectSpecification: React.FC<{
  register: any,
  complex: IComplex | undefined,
  defaultValues: any,
  onChange?: (data: IDevelopmentSpecificationProp[], prop: string) => void
}> = ({
  complex, defaultValues, register, onChange,
}) => {
  const {
    purchaseTermsOptions,
    propertyClassesOptions,
    salesStatusesOptions,
    constructionPhasesOptions,
    completionDatesOptions,
    fetchSpecifications,
  } = useGetDeveloperSpecifications(complex?.developers[0]);

  useEffect(() => {
    fetchSpecifications();
  }, []);

  const _onChange = (data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>, prop: string) => {
    const value = Array.isArray(data) ? data.map((item) => item.value) : data.value ? [data.value] : [];
    if (onChange) {
      onChange(value, prop);
    }
  };

  return (
    <>
      <Box flex="1" display="flex" gap="40px">
        <BoxFormAutocomplete
          multiple
          label={translations.purchaseTerms}
          name={`developmentSpecification.${DeveloperPropertyType.purchaseTerms}`}
          options={purchaseTermsOptions}
          defaultValue={defaultValues[DeveloperPropertyType.purchaseTerms]}
          onChange={(data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>) => _onChange(data, `developmentSpecification.${DeveloperPropertyType.purchaseTerms}`)}
          {...register(`developmentSpecification.${DeveloperPropertyType.purchaseTerms}`)}
        />
        <BoxFormAutocomplete
          label={translations.propertyClass}
          name={`developmentSpecification.${DeveloperPropertyType.propertyClasses}`}
          options={propertyClassesOptions}
          defaultValue={defaultValues[DeveloperPropertyType.propertyClasses]}
          onChange={(data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>) => _onChange(data, `developmentSpecification.${DeveloperPropertyType.propertyClasses}`)}
          {...register(`developmentSpecification.${DeveloperPropertyType.propertyClasses}`)}
        />
      </Box>
      <Box flex="1" display="flex" gap="40px">
        <BoxFormAutocomplete
          label={translations.salesStatus}
          name={`developmentSpecification.${DeveloperPropertyType.salesStatuses}`}
          options={salesStatusesOptions}
          defaultValue={defaultValues[DeveloperPropertyType.salesStatuses]}
          onChange={(data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>) => _onChange(data, `developmentSpecification.${DeveloperPropertyType.salesStatuses}`)}
          {...register(`developmentSpecification.${DeveloperPropertyType.salesStatuses}`)}
        />
        <BoxFormAutocomplete
          label={translations.constructionPhase}
          name={`developmentSpecification.${DeveloperPropertyType.constructionPhases}`}
          options={constructionPhasesOptions}
          defaultValue={defaultValues[DeveloperPropertyType.constructionPhases]}
          onChange={(data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>) => _onChange(data, `developmentSpecification.${DeveloperPropertyType.constructionPhases}`)}
          {...register(`developmentSpecification.${DeveloperPropertyType.constructionPhases}`)}
        />
      </Box>
      <Box flex="1" display="flex" gap="40px">
        <BoxFormAutocomplete
          label={translations.completionDate}
          name={`developmentSpecification.${DeveloperPropertyType.completionDates}`}
          options={completionDatesOptions}
          defaultValue={defaultValues[DeveloperPropertyType.completionDates]}
          onChange={(data: IDevelopmentSpecificationPropChange<IDevelopmentSpecificationProp>) => _onChange(data, `developmentSpecification.${DeveloperPropertyType.completionDates}`)}
          {...register(`developmentSpecification.${DeveloperPropertyType.completionDates}`)}
        />
        <Box width="100%" />
      </Box>
    </>
  );
};
