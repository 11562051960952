import React from 'react';

import {
  NoMatch404,
  Compare,
  MainPage,
  MainPageApartments,
  Favorites,
  Authorization,
  Profile,
  ErrorPage,
} from 'src/containers';
import { MainPageApartmentsV2 } from 'src/containers/MainPageApartmentsV2';

import { locals } from 'src/constants/locals';
import { serverPath } from 'src/constants';
import { translations } from './constants/translations';

export interface IRouteList {
  path: string;
  name: string;
  Component?: React.FunctionComponentElement<any>;
  exact?: boolean;
  disabledForManager?: boolean,
  disabledForSupervisor?: boolean,
  disabledForProd?: boolean,
  isAbsoluteLink?: boolean,
}

export const pathList = {
  home: `${serverPath}`,
  floors: `${serverPath}floors`,
  own: `${serverPath}own`,
  ownV2: `${serverPath}ownV2`,
  favorites: `${serverPath}favorites`,
  compare: `${serverPath}compare`,
  auth: `${serverPath}auth`,
  profile: `${serverPath}profile`,
  profileComplexes: `${serverPath}profile/complexes`,
  errorPage: `${serverPath}error`,
  error404: '*',
};

export const routesWithoutId: IRouteList[] = [
  { path: pathList.home, name: locals.appTranslations.appHomePage, Component: <MainPage /> },
  { path: `${pathList.floors}`, name: locals.appTranslations.appHomePage, Component: <MainPage /> },
  {
    path: `${pathList.floors}/:apartmentFloor/:apartmentSection/:apartmentHouse/:apartmentNumeration`,
    name: locals.appTranslations.appHomePage,
    Component: <MainPage />,
  },
  { path: `${pathList.ownV2}/:Id`, name: locals.appTranslations.appPlacement, Component: <MainPageApartments /> },
  { path: `${pathList.own}/:Id`, name: locals.appTranslations.appPlacement, Component: <MainPageApartmentsV2 /> },
  { path: pathList.favorites, name: locals.appTranslations.appFavorites, Component: <Favorites /> },
  { path: pathList.compare, name: locals.appTranslations.appCompare, Component: <Compare /> },
];

export const routesWithId: IRouteList[] = [
  {
    path: pathList.auth, name: translations.authTitle, Component: <Authorization />, exact: true,
  },
  {
    path: pathList.profile, name: translations.title, Component: <Profile />, exact: false,
  },
  {
    path: `${pathList.errorPage}/:type`, name: 'Error', Component: <ErrorPage />,
  },
];

export const notFoundRoute: IRouteList = {
  path: pathList.error404, name: '404', Component: <NoMatch404 />,
};
