import React from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Box, OutlinedInputProps, TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

interface IBoxFormTextField {
  label?: string;
  name?: string;
  value?: string;
  inputRef: React.Ref<any>;
  placeholder?: string,
  startAdornment?: React.ReactNode,
  inputStyle?: {[key: string]: string},
  props?: TextFieldProps,
}

export const BoxFormTextField: React.FC<IBoxFormTextField> = ({
  label,
  name,
  value,
  inputRef,
  startAdornment,
  placeholder,
  ...props
}) => {
  return (
    <Box mb={6} width="100%" display="flex" flexDirection="column">
      <Box mb={2}>
        { label && (label)}
      </Box>
      <Box className="text-field-wrapper">
        {!startAdornment && (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            value={value}
            placeholder={placeholder}
            inputRef={inputRef}
            {...props}
          />
        )}
        {startAdornment && (
          <OutlinedInput
            fullWidth
            startAdornment={startAdornment}
            value={value}
            placeholder={placeholder}
            name={name}
            inputRef={inputRef}
            {...props as OutlinedInputProps}
          />
        )}
      </Box>
    </Box>
  );
};
