import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import { IHouse } from 'src/store/crosstable/reducer';

export const getHouseSpecificationsDefaultValues = (house: IHouse) => {
  const unselectedOption = { label: 'not selected', value: undefined };
  const lang = house.complex.language;
  const { purchaseTerms } = house.developmentSpecification;
  const propertyClasses = house.developmentSpecification.propertyClasses[0];
  const salesStatuses = house.developmentSpecification.salesStatuses[0];
  const constructionPhases = house.developmentSpecification.constructionPhases[0];
  const completionDates = house.developmentSpecification.completionDates[0];
  return {
    [DeveloperPropertyType.purchaseTerms]: purchaseTerms.length ? purchaseTerms.map((item) => ({ label: item.name[lang], value: item })) : [unselectedOption],
    [DeveloperPropertyType.propertyClasses]: propertyClasses?.name ? { label: propertyClasses.name[lang], value: propertyClasses } : unselectedOption,
    [DeveloperPropertyType.salesStatuses]: salesStatuses?.name ? { label: salesStatuses.name[lang], value: salesStatuses } : unselectedOption,
    [DeveloperPropertyType.constructionPhases]: constructionPhases?.name ? { label: constructionPhases.name[lang], value: constructionPhases } : unselectedOption,
    [DeveloperPropertyType.completionDates]: completionDates?.name ? { label: `${completionDates.prefix[lang]} ${completionDates.name[lang]} ${completionDates.suffix[lang]}`, value: completionDates } : unselectedOption,
  };
};
