import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { ButtonApp } from 'src/components/Button';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetComplex, useGetHouses, useSetDocumentTitle } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import { IHouse } from 'src/store/crosstable/reducer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { appIcon } from 'src/assets';
import { HouseModal } from 'src/components/profileComponents/Storage/modals/HouseEdit';

export const Storage = () => {
  const { id }: { id: string } = useParams();
  const { complex } = useGetComplex(id);
  const { houses, fetchHouse } = useGetHouses(id);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [houseEdit, setHouseEdit] = useState<IHouse | undefined>();

  useSetDocumentTitle(translations.storage);

  const onEdit = () => {
    setModalOpen(true);
  };

  const handleChangeOpen = (isOpen: boolean) => {
    setModalOpen(isOpen);
    if (!isOpen) {
      setHouseEdit(undefined);
    }
  };

  if (!complex) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const {
    language,
    title,
  } = complex;
  const crumbs: ICrumbs[] = [
    {
      name: translations.complexesLink,
      url: `/profile/${pathList.complexes}`,
    },
    {
      name: `${translations.settings} ${title[language]}`,
      url: `/profile/${pathList.complexes}/${id}/`,
    },
    {
      name: `${translations.storage} ${title[language]}`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <ButtonApp
        text={translations.goBack}
        onClick={() => {
          history.push(`/profile/${pathList.complexes}/${id}/`);
        }}
        type="button"
        color="inherit"
        size="small"
        isCapitalize
        variant="outlined"
        startIcon={<ArrowBackIcon />}
      />
      <Box fontSize="32px" mb={1} mt={2} fontWeight={700}>
        {`${translations.storage} ${title[language]}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        {
          houses.length && houses.map((house) => (
            <Box
              borderBottom={`1px solid ${borderColor}`}
              display="flex"
              justifyContent="space-between"
              py={2}
              px={2}
              key={house['@id']}
            >
              <Box fontSize="20px">
                {`${translations.building} ${house.numeration[language]}`}
              </Box>
              <Box mr={2} display="flex" gap={2}>
                <ButtonApp
                  text={translations.edit}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  style={{
                    padding: '15px 30px',
                    fontSize: '18px',
                  }}
                  startIcon={<img src={appIcon.iconEdit.default} alt="edit statuses" />}
                  onClick={() => {
                    setHouseEdit(house);
                    onEdit();
                  }}
                />
                <ButtonApp
                  text={translations.manageStock}
                  onClick={() => {
                    history.push(`/profile/${pathList.complexes}/${id}/storage/${house.id}`);
                  }}
                  type="button"
                  color="primary"
                  size="small"
                  isCapitalize
                  variant="contained"
                  endIcon={<ChevronRightIcon />}
                />
              </Box>
            </Box>
          ))
        }
        {modalOpen && houseEdit && (
          <HouseModal
            isOpen={modalOpen}
            handleChangeOpen={handleChangeOpen}
            refresh={fetchHouse}
            complex={complex}
            house={houseEdit}
          />
        )}
      </Box>
    </Box>
  );
};
