import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import { IComplex } from 'src/typings/complexes';

export const getComplexSpecificationsDefaultValues = (complex: IComplex | undefined) => {
  const lang = complex?.language;
  const purchaseTerms = complex?.developmentSpecification.purchaseTerms[0];
  const propertyClasses = complex?.developmentSpecification.propertyClasses[0];
  const salesStatuses = complex?.developmentSpecification.salesStatuses[0];
  const constructionPhases = complex?.developmentSpecification.constructionPhases[0];
  const completionDates = complex?.developmentSpecification.completionDates[0];
  return {
    [DeveloperPropertyType.purchaseTerms]: purchaseTerms?.name && lang ? { label: purchaseTerms.name[lang], value: purchaseTerms } : undefined,
    [DeveloperPropertyType.propertyClasses]: propertyClasses?.name && lang ? { label: propertyClasses.name[lang], value: propertyClasses } : undefined,
    [DeveloperPropertyType.salesStatuses]: salesStatuses?.name && lang ? { label: salesStatuses.name[lang], value: salesStatuses } : undefined,
    [DeveloperPropertyType.constructionPhases]: constructionPhases?.name && lang ? { label: constructionPhases.name[lang], value: constructionPhases } : undefined,
    [DeveloperPropertyType.completionDates]: completionDates?.name && lang ? { label: completionDates.name[lang], value: completionDates } : undefined,
  };
};

export const getComplexLocationsDefaultValues = (complex: IComplex | undefined) => {
  const lang = complex?.language;
  const latitude = complex?.location.latitude;
  const longitude = complex?.location.longitude;
  const countryManual = complex?.location.countryManual;
  const cityManual = complex?.location.cityManual;
  const districtManual = complex?.location.districtManual;
  const nearestMetroStationManual = complex?.location.nearestMetroStationManual;
  const nearestMetroBranchManual = complex?.location.nearestMetroBranchManual;
  const nearestMetroWalkMinutesManual = complex?.location.nearestMetroWalkMinutesManual;
  const nearestMetroDriveMinutesManual = complex?.location.nearestMetroDriveMinutesManual;
  const countryAutoCalculated = complex?.location.countryAutoCalculated;
  const cityAutoCalculated = complex?.location.cityAutoCalculated;
  const districtAutoCalculated = complex?.location.districtAutoCalculated;
  const nearestMetroStationAutoCalculated = complex?.location.nearestMetroStationAutoCalculated;
  const nearestMetroBranchAutoCalculated = complex?.location.nearestMetroBranchAutoCalculated;
  const nearestMetroWalkMinutesAutoCalculated = complex?.location.nearestMetroWalkMinutesAutoCalculated;
  const nearestMetroDriveMinutesAutoCalculated = complex?.location.nearestMetroDriveMinutesAutoCalculated;
  return {
    latitude: latitude && lang ? latitude[lang] : undefined,
    longitude: longitude && lang ? longitude[lang] : undefined,
    countryManual: countryManual && lang ? countryManual[lang] : undefined,
    cityManual: cityManual && lang ? cityManual[lang] : undefined,
    districtManual: districtManual && lang ? districtManual[complex.language] : undefined,
    nearestMetroStationManual: nearestMetroStationManual && lang ? nearestMetroStationManual[lang] : undefined,
    nearestMetroBranchManual: nearestMetroBranchManual && lang ? nearestMetroBranchManual[lang] : undefined,
    nearestMetroWalkMinutesManual: nearestMetroWalkMinutesManual && lang ? nearestMetroWalkMinutesManual[lang] : undefined,
    nearestMetroDriveMinutesManual: nearestMetroDriveMinutesManual && lang ? nearestMetroDriveMinutesManual[lang] : undefined,
    countryAutoCalculated: countryAutoCalculated && lang ? countryAutoCalculated[lang] : undefined,
    cityAutoCalculated: cityAutoCalculated && lang ? cityAutoCalculated[lang] : undefined,
    districtAutoCalculated: districtAutoCalculated && lang ? districtAutoCalculated[lang] : undefined,
    nearestMetroStationAutoCalculated: nearestMetroStationAutoCalculated && lang ? nearestMetroStationAutoCalculated[lang] : undefined,
    nearestMetroBranchAutoCalculated: nearestMetroBranchAutoCalculated && lang ? nearestMetroBranchAutoCalculated[lang] : undefined,
    nearestMetroWalkMinutesAutoCalculated: nearestMetroWalkMinutesAutoCalculated && lang ? nearestMetroWalkMinutesAutoCalculated[lang] : undefined,
    nearestMetroDriveMinutesAutoCalculated: nearestMetroDriveMinutesAutoCalculated && lang ? nearestMetroDriveMinutesAutoCalculated[lang] : undefined,
  };
};
