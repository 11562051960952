import React, {
  FC, useEffect, useState,
} from 'react';
import { DeveloperPropertyItem } from 'src/components/profileComponents/Developers/components/DeveloperPropertyItem';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import Box from '@mui/material/Box';
import { IDevelopmentSpecificationProp } from 'src/typings/complexes';
import { useStyles } from './styles';

export const DeveloperPropertyList: FC<{
  list: IDevelopmentSpecificationProp[],
  lang: string,
  onChange?: (data: any) => void}> = ({ list, onChange, lang }) => {
    const [optionsList, setOptionsList] = useState<IDevelopmentSpecificationProp[]>(list);
    const styles = useStyles({});

    useEffect(() => {
      setOptionsList(list);
    }, [list]);

    const remove = (index: number) => {
      let updatedList = [...optionsList];
      updatedList.splice(index, 1);
      updatedList = updatedList.map((item, updatedListIndex) => ({ ...item, sortOrder: updatedListIndex }));
      setOptionsList(updatedList);
      _onChange(updatedList);
    };

    const change = (index: number, value: string, type?: 'prefix' | 'suffix') => {
      const tempOptions = [...optionsList];
      if (type) {
        tempOptions[index][type][lang] = value;
      } else {
        tempOptions[index].name[lang] = value;
      }
      setOptionsList(tempOptions);
      _onChange(tempOptions);
    };

    const _onChange = (data: IDevelopmentSpecificationProp[]) => {
      if (onChange) {
        onChange(data);
      }
    };

    return (
      <ReactSortable<IDevelopmentSpecificationProp>
        className={styles.developerPropertyWrapper}
        animation={150}
        handle=".drag-handle"
        list={optionsList.map((entity) => ({ ...entity, chosen: false }))}
        setList={(newList, sortable: Sortable | null, store: Store) => {
          if (store && store.dragging && store.dragging.props
          && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
            const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
            setOptionsList(orderedList);
            _onChange(orderedList);
          }
        }}
      >

        { optionsList.map((option, index) => (
          <Box key={option.id}>
            <DeveloperPropertyItem
              option={option}
              lang={lang}
              index={index}
              change={change}
              remove={() => remove(index)}
            />
          </Box>
        )) }
      </ReactSortable>
    );
  };
