export type DeveloperProperty = {
  id?: number;
  value: string,
  sortOrder: number,
  prefix?: string,
  suffix?: string,
}

export enum DeveloperPropertyType {
  purchaseTerms = 'purchaseTerms',
  propertyClasses = 'propertyClasses',
  salesStatuses = 'salesStatuses',
  constructionPhases = 'constructionPhases',
  completionDates = 'completionDates'
}

export type IDeveloperPropertyType = 'prefix' | 'suffix';
