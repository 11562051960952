/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDeveloper, useGetAuthData } from 'src/hooks';
import { CRMNames, IntegrationType } from 'src/typings/app';
import { toUpperCase } from 'src/helpers/toUpperCase';
import { StageModal } from 'src/components/profileComponents/BitrixCategories/components/StageModal';
import { patchNetHuntStages, postNetHuntStages } from 'src/api';
import {
  projectType,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { useGetNetHuntStages } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetNetHuntStages';
import { useGetStatuses } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetStatuses';
import { StagesTable } from 'src/components/profileComponents/Integrations/Nethunt/components/StagesTable';
import { useGetFunnel } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetFunnel';
import { IComplex } from 'src/typings/complexes';
import { useGetFunnelFields } from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetFunnelFields';
import { useGetProject } from 'src/components/profileComponents/Integrations/Bitrix/hooks/useGetProject';
import { Fields } from 'src/components/profileComponents/Integrations/Nethunt/components/Fields';
import {
  useGetPlacementPropertiesFields,
} from 'src/components/profileComponents/Integrations/Nethunt/hooks/useGetPlacementPropertiesFields';
import {
  ResponsibleAndDealSelector,
} from 'src/components/profileComponents/Integrations/Nethunt/components/DirectorySelector';
import { StatusOnZeroDeal } from './components/StatusOnZeroDeal';
import { StatusConnectDeal } from './components/StatusConnectDeal';
import { useStyles } from './styles';

export const NetHuntCategories: React.FC<{integration: IntegrationType}> = ({ integration }) => {
  const styles = useStyles({});
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { id, funnelId }: { id: string, funnelId: string } = useParams();

  const ref = useRef<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [errorStages, setErrorStages] = useState('');
  const [complexes, setComplexes] = useState<IComplex[]>([]);
  const { isLoading, developer } = useGetDeveloper(id);
  const { funnel, isLoading: isLoadingFunnel, fetchFunnel } = useGetFunnel();
  const {
    netHuntStages, isLoading: isLoadingStages, fetchStages,
  } = useGetNetHuntStages();
  const {
    statuses, sortedStatuses, fetchStatuses, isLoading: isLoadingStatuses,
  } = useGetStatuses();
  const { project, fetchProject } = useGetProject(developer && developer[projectType[integration]]);
  const {
    fields, fetchFields,
  } = useGetFunnelFields();
  const { placementPropertiesFields, getPlacementPropertiesFields } = useGetPlacementPropertiesFields();

  useEffect(() => {
    fetchProject();
    fetchFunnel(funnelId);
    fetchStages(funnelId);
    getPlacementPropertiesFields();
  }, []);

  useEffect(() => {
    if (project && funnel) {
      if (funnel.externalFolderId) {
        fetchFields(project, funnel.externalFolderId);
      }
      fetchStatuses(funnel.complexes);
    }
  }, [project, funnel]);

  useEffect(() => {
    if (funnel && developer) {
      const complexesResult: IComplex[] = [];
      funnel.complexes.forEach((complexIri: string) => {
        const found = developer.complexes.find((complex: IComplex) => complex['@id'] === complexIri);
        if (found) complexesResult.push(found);
      });
      setComplexes(complexesResult);
    }
  }, [funnel, developer]);

  const handleChange = (stage: string, key: string, value: any) => {
    if (ref.current[stage]) {
      ref.current[stage][key] = value;
    } else {
      ref.current[stage] = {
        [key]: value,
      };
    }
  };

  const onSubmitStages = async (data:any) => {
    if (funnel?.['@id']) {
      const res = await postNetHuntStages(jwt, {
        externalName: data.stageName,
        funnel: funnel['@id'],
        placementStatuses: statuses && statuses.length ? statuses?.map((item) => item['@id']) : [],
        priority: 0,
        sortOrder: 0,
        unlinkPlacementAtStageWithLowerPriority: false,
        unlinkPlacementAtStageWithHigherPriority: false,
        unlinkPlacementAtStageWithSamePriority: false,
        unlinkAllPlacementsAtAllOtherStages: false,
        unlinkPlacementAtThisStage: false,
        blockMoveAndLinkDealsWithPlacementAtOtherStages: false,
        blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: false,
      });
      if (res.ok) {
        setModalOpen(false);
        fetchStages(funnelId);
      } else {
        setErrorStages(res.data['hydra:description']);
      }
    }
  };

  const saveChanges = () => {
    if (funnel) {
      Object.keys(ref.current).forEach((stageKey) => {
        patchNetHuntStages(jwt, stageKey, ref.current[stageKey]).then();
      });
    }
  };

  if (isLoading || isLoadingFunnel || !developer || isLoadingStages || isLoadingStatuses || !sortedStatuses) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const developerName = developer.name[developer.language];
  const currentIntegrationName = toUpperCase(integration) as IntegrationType;

  const crumbs: ICrumbs[] = [
    {
      name: translations.developersLink,
      url: `/profile/${pathList.developers}`,
    },
    {
      name: `Developer ${developerName}`,
      url: `/profile/${pathList.developers}/${id}`,
    },
    {
      name: `${currentIntegrationName} Integration`,
      url: `/profile/${pathList.developers}/${id}/${integration}/integration`,
    },
    {
      name: `${currentIntegrationName} Funnel Setup`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`${integration} Funnel Setup | ${developerName}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box>
        <Box display="flex" mb={2} justifyContent="space-between">
          <ButtonApp
            text="Add Stage"
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => {
              setModalOpen(true);
            }}
            size="large"
            className={styles.btn}
          />
          <ButtonApp
            text="Save changes"
            color="primary"
            variant="contained"
            onClick={saveChanges}
            size="large"
            className={styles.btn}
          />
        </Box>
        <StagesTable
          stages={netHuntStages}
          complexes={complexes}
          statuses={sortedStatuses}
          integration={CRMNames.netHunt}
          handleChange={handleChange}
        />
        <Box mb={4}>
          <StatusOnZeroDeal
            complexes={complexes}
            statuses={sortedStatuses}
          />
        </Box>
        <StatusConnectDeal
          complexes={complexes}
          statuses={sortedStatuses}
        />
        {funnel && placementPropertiesFields && (
          <Box mb={4}>
            <Fields integration={integration} fields={placementPropertiesFields} entityValues={fields} funnel={funnel} fetchFunnel={fetchFunnel} />
          </Box>
        )}
        <ResponsibleAndDealSelector funnel={funnel} funnelFields={fields} />
      </Box>
      {modalOpen && (
      <StageModal
        integration={integration}
        isOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        fecthStages={fetchStages}
        onSubmitAction={onSubmitStages}
        errorMessage={errorStages}
      />
      )}
    </Box>
  );
};
