import { getCountries } from 'src/api/countries';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useEffect, useState } from 'react';
import { ICountry } from 'src/typings/countries';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';

export const useCountries = () => {
  const jwt = useGetAuthData().getJWT();
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [countriesCodes, setCountriesCodes] = useState<string[]>([]);
  const [countriesNames, setCountriesNames] = useState<string[]>([]);
  const [countriesCodesOptions, setCountriesCodesOptions] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);

  useEffect(() => {
    getCountriesList();
  }, []);

  const getCountriesList = async () => {
    const { data: list } = await getCountries(jwt);
    setCountriesList(list);
    setCountriesCodes(list.map((lang: ICountry) => lang.code).sort());
    setCountriesNames(list.map((lang: ICountry) => lang.name).sort());
    setCountriesCodesOptions(list.sort().map((lang: ICountry) => ({ label: lang.code, value: lang.code })));
  };

  return {
    countriesList,
    countriesCodes,
    countriesNames,
    countriesCodesOptions,
    getCountriesList,
  };
};
