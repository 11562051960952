/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Modal, Backdrop,
  Fade, Paper,
  Snackbar, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import {
  IComplex, IDevelopmentSpecificationProp,
} from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import {
  putHouseData,
} from 'src/api';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ProjectSpecification } from 'src/components/profileComponents/Complexes/components/ProjectSpecification';
import { IHouse } from 'src/store/crosstable/reducer';
import { getHouseSpecificationsDefaultValues } from 'src/helpers/houseEdit';
import { useStyles } from './styles';

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: (isOpen: boolean) => void;
  refresh?: Function;
  complex: IComplex;
  house: IHouse;
}

const schema = yup.object().shape({});

export const HouseModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, complex, house, refresh,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const jwt = useGetAuthData().getJWT();
  const [tabValue, setTabValue] = useState<string>('2');

  const {
    register, handleSubmit, formState, setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const specificationsDefaultValues = getHouseSpecificationsDefaultValues(house);

  const onSubmit = async (formData:any) => {
    const saveData: Partial<IHouse> = {
      developmentSpecification: house?.developmentSpecification,
    };
    if (formData.developmentSpecification) {
      Object.keys(formData.developmentSpecification).forEach((key) => {
        if (key && formData.developmentSpecification[key] && saveData.developmentSpecification) {
          // @ts-ignore
          saveData.developmentSpecification[key] = (
            formData.developmentSpecification[key]
            && Array.isArray(formData.developmentSpecification[key])
            && !formData.developmentSpecification[key].length
          ) ? [] : formData.developmentSpecification[key];
        }
      });
    }

    let res;

    if (house) {
      res = await putHouseData(jwt, house.id, saveData);
    }

    if (res.ok) {
      handleChangeOpen(false);
      if (refresh) {
        refresh();
      }
    } else {
      setError(res.data['hydra:description']);
    }
  };

  const developerPropertyChange = (data: IDevelopmentSpecificationProp[], prop: string) => {
    setValue(prop, data);
  };

  const close = () => handleChangeOpen(false);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="800px" maxHeight="90vh" overflow="auto" p="52px">
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {translations.buildingEditing}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab disabled label="MAIN INFORMATION" value="1" />
                        <Tab label="Project specification" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" />
                    <TabPanel value="2">
                      <ProjectSpecification
                        complex={complex}
                        defaultValues={specificationsDefaultValues}
                        register={register}
                        onChange={(data, prop) => developerPropertyChange(data, prop)}
                      />
                    </TabPanel>
                    <Box display="flex" justifyContent="flex-end" gap="20px">
                      <ButtonApp
                        onClick={close}
                        text={translations.cancel}
                        color="primary"
                        variant="outlined"
                        type="button"
                        className={styles.btn}
                      />
                      <ButtonApp
                        text={translations.saveChanges}
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={styles.btn}
                        disabled={!formState.isValid}
                      />
                    </Box>
                  </TabContext>
                </form>
              </Box>

              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  { error }
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
