import { getLanguages } from 'src/api/language';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useEffect, useState } from 'react';
import { ILanguage } from 'src/typings/language';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';

export const useLanguage = () => {
  const jwt = useGetAuthData().getJWT();
  const [languagesList, setLanguagesList] = useState<ILanguage[]>([]);
  const [languagesCodes, setLanguagesCodes] = useState<string[]>([]);
  const [languagesNames, setLanguagesNames] = useState<string[]>([]);
  const [languagesCodesOptions, setLanguagesCodesOptions] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);

  useEffect(() => {
    getLanguagesList();
  }, []);

  const getLanguagesList = async () => {
    const { data: list } = await getLanguages(jwt);
    setLanguagesList(list);
    setLanguagesCodes(list.map((lang: ILanguage) => lang.code).sort());
    setLanguagesNames(list.map((lang: ILanguage) => lang.name).sort());
    setLanguagesCodesOptions(list.sort().map((lang: ILanguage) => ({ label: lang.code, value: lang.code })));
  };

  return {
    languagesList,
    languagesCodes,
    languagesNames,
    languagesCodesOptions,
    getLanguagesList,
  };
};
