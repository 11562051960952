import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { IBoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete/types';

export const BoxFormAutocomplete: React.FC<IBoxFormAutocomplete> = ({
  label, name, options, value, onChange, defaultValue, multiple,
}) => {
  return (
    <Box mb={6} width="100%" display="flex" flexDirection="column">
      { label }
      <Box mt={2}>
        <Autocomplete
          multiple={multiple || Array.isArray(value) || Array.isArray(defaultValue)}
          id={name}
          options={options}
          value={value || undefined}
          disableCloseOnSelect={Array.isArray(value)}
          disableClearable
          onChange={(ev, currValue) => onChange && onChange(currValue)}
          isOptionEqualToValue={(option, equalToValue) => JSON.stringify(option.value) === JSON.stringify(equalToValue.value)}
          // getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} placeholder="" sx={{ fontSize: '14px' }} />
          )}
          defaultValue={defaultValue}
        />
      </Box>
    </Box>
  );
};
