import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { whiteColor } from 'src/constants/styles';

export const useStyles = makeStyles(() => createStyles({
  developerHover: {
    transition: 'all 0.5s',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      boxShadow: '1px 0px 5px -1px black',
    },
  },
  editBtn: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 2,
    color: whiteColor,
    backgroundColor: whiteColor,
    transition: '0.3s all',
    '&:hover': {
      backgroundColor: 'rgb(108 89 230 / 70%)',
    },
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  closeBtn: {
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
  addBtnWrapper: {
    marginTop: '21px',
    marginBottom: '36px',
  },
  addBtn: {
    backgroundColor: '#F9E8FF',
    padding: '10px 16px',
    borderRadius: '20px',
    height: '40px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.6px',
  },
}));
