import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  developerPropertyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));
