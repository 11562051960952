import {
  INetHuntFeedbackMessageConfiguration,
  INetHuntFunnel,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { IAdminPanel, IFrontConfigBase } from 'src/store/type-displayed-spinner/types';
import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import { IUsers } from './user';
import { IDField, IDogeFields, IDogeFieldsOptional } from './global-config';

export enum IGtmEventsTypes {
  formSubmit = 'FormSubmitButtonClick',
  pdfDownload = 'DownloadPDFButtonClick',
}
export type IStage = IBitrixStage | ICreatioStage | INetHuntStages;

export interface IBitrixStage extends IDogeFields {
  bitrixCategory: string;
  bitrixDeals: string[];
  bitrixExternalStageId: string;
  bitrixExternalStageName: string;
  id: number;
  priority: number;
  placementStatuses: string[];
}

export interface ICreatioStage extends Omit<IDogeFields, '@context'> {
  id: number;
  creatioProject: string;
  externalId: string;
  externalName: string;
  priority: number;
  sortSequence: number;
  unlinkPlacementAtStageWithLowerPriority: boolean;
  unlinkPlacementAtStageWithHigherPriority: boolean;
  unlinkPlacementAtStageWithSamePriority: boolean;
  unlinkAllPlacementsAtAllOtherStages: boolean;
  unlinkPlacementAtThisStage: boolean;
  blockMoveAndLinkDealsWithPlacementAtOtherStages: boolean;
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: boolean;
  placementStatuses: string[];
}

export interface INetHuntStages extends IDogeFieldsOptional {
  externalName: string,
  funnel: string,
  placementStatuses: string[],
  priority: number,
  sortOrder: number,
  unlinkPlacementAtStageWithLowerPriority: boolean,
  unlinkPlacementAtStageWithHigherPriority: boolean,
  unlinkPlacementAtStageWithSamePriority: boolean,
  unlinkAllPlacementsAtAllOtherStages: boolean,
  unlinkPlacementAtThisStage: boolean,
  blockMoveAndLinkDealsWithPlacementAtOtherStages: boolean,
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: boolean
}

export interface IStatusOnZeroDeals extends IDogeFields {
  id: number,
  complex: IComplex,
  placementStatus: IStatus
}

export interface ICategory extends IDogeFields {
  bitrixCategoryPlacementStatusOnZeroDeals: IStatusOnZeroDeals[];
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: IComplex[];
  bitrixStages: string[];
  id: number;
}

export interface ICategoryChild extends IDogeFields {
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: string[];
  bitrixStages: string[];
}

export interface IProjectFrontDealConfig extends IDogeFields {
  id: number;
  isShowDealDataForManagers: boolean;
}

export interface IProjectFrontConfig extends IDogeFields {
  id: number;
  bitrixProject: string;
  dealConfig: IProjectFrontDealConfig;
}

export interface IProject extends IDogeFields {
  id: number | string;
  domain: string;
  webhookUrl: string;
  workspaceId: string;
  authKey: string;
  authEmail: string;
  formEndpoint: string;
  funnels: INetHuntFunnel[] | [],
  formMessageConfiguration: INetHuntFeedbackMessageConfiguration | null;
  developer: string;
  bitrixCategories: ICategoryChild[];
  login: string;
  password: string;
  oauth2Enpoint: string;
  oauth2ClientId: string;
  oauth2ClientSecret: string;
  creatioStages: IStage[];
  serverIp: string;
  leadEndpointSuffix: string;
  leadSchemaName: 'Lead';
  leadSchemaUid: string;
  opportunityEndpointSuffix: string;
  opportunitySchemaName: 'Opportunity';
  opportunitySchemaUid: string;
  opportunityStageEndpointSuffix: string;
  opportunityStageSchemaName: 'OpportunityStage';
  frontConfig: IProjectFrontConfig;
}

export interface IDevelopmentSpecificationProp extends IDogeFields {
  id: number,
  name: {[key: string]: string};
  sortOrder: number;
  type: DeveloperPropertyType,
  prefix: {[key: string]: string},
  suffix: {[key: string]: string},
}
export interface IDevelopmentSpecification extends IDogeFields {
  id: number,
  purchaseTerms: IDevelopmentSpecificationProp[] | [],
  propertyClasses: IDevelopmentSpecificationProp[] | [],
  salesStatuses: IDevelopmentSpecificationProp[] | [],
  constructionPhases: IDevelopmentSpecificationProp[] | [],
  completionDates: IDevelopmentSpecificationProp[] | [],
}

export interface IComplexLocation extends IDogeFields, IDField {
  latitude: {[key: string]: string} | null;
  longitude: {[key: string]: string} | null;
  countryManual: {[key: string]: string} | null;
  cityManual: {[key: string]: string} | null;
  districtManual: {[key: string]: string} | null;
  nearestMetroStationManual: {[key: string]: string} | null;
  nearestMetroBranchManual: {[key: string]: string} | null;
  nearestMetroWalkMinutesManual: {[key: string]: string} | null;
  nearestMetroDriveMinutesManual: {[key: string]: string} | null;
  countryAutoCalculated: {[key: string]: string} | null;
  cityAutoCalculated: {[key: string]: string} | null;
  districtAutoCalculated: {[key: string]: string} | null;
  nearestMetroStationAutoCalculated: {[key: string]: string} | null;
  nearestMetroBranchAutoCalculated: {[key: string]: string} | null;
  nearestMetroWalkMinutesAutoCalculated: {[key: string]: string} | null;
  nearestMetroDriveMinutesAutoCalculated: {[key: string]: string} | null;
}

export interface IDeveloper extends IDogeFields {
  id: number;
  logoFileUri: IFileUri | null;
  name: { [key: string]: string };
  language: string;
  secondaryLanguages: string[] | null;
  bitrixProject: string | null;
  creatioProject: string | null;
  netHuntIntegration: string | null;
  complexes: IComplex[];
  developmentSpecification: IDevelopmentSpecification
}

export interface IAmoPipeline extends IDogeFields {
  amoProject: string | null;
  amo_id: number;
  id: number;
}

export interface IGtmEvents extends IDogeFields, IDField {
  eventJson: string,
  type: IGtmEventsTypes,
}

export interface IGtm extends IDogeFields, IDField {
  events: IGtmEvents[],
}

export interface IComplexFrontConfigWebsite extends IFrontConfigBase {
  gtm: IGtm,
}

export interface IComplexFrontConfig extends IDogeFields, IDField {
  adminPanel: IAdminPanel,
  website: IComplexFrontConfigWebsite,
}

export interface IComplex extends IDogeFields {
  amo_pipeline: IAmoPipeline | null;
  bitrixComplexData: null;
  developers: IDeveloper[];
  id: number | string;
  logoFileUri: IFileUri | null,
  previewFileUri: IFileUri | null,
  title: { [key: string]: string; },
  address: { [key: string]: string; },
  secondaryLanguages: string[] | null,
  crosstableLanguage: string | null,
  crosstableSecondaryLanguages: string[] | null,
  moduleLanguage: string | null,
  moduleSecondaryLanguages: string[] | null,
  language: string;
  complexShortName: string;
  clientModuleAccessUrls: IComplexClientModuleAccessUrls[] | []
  clientModuleConfig: IClientModuleConfig,
  showInCrosstable: boolean;
  users: IUsers[];
  frontConfig: IComplexFrontConfig;
  developmentSpecification: IDevelopmentSpecification;
  location: IComplexLocation
}

export interface IComplexClientModuleAccessUrls extends IDogeFields, IDField {
  url: string,
}

export interface IFileUri extends IDogeFields {
  id: number,
  domain: string,
  path: string,
  name: string,
  extension: string,
  resolutions: null,
  alternativeTypes: null,
  fileUrl: string,
}

export interface IClientModuleConfig extends IDogeFields, IDField {
  formDefaultCountry: string,
}

export enum TypeOfStatus {
  fixed = 'fixed time',
  static = 'static'
}

export interface IStatus extends IDogeFields {
  amoCanBeSetForDeal: boolean;
  amo_can_be_set_for_deal: boolean;
  amo_only_one_deal_placement: boolean;
  can_be_changed: boolean;
  can_be_set: boolean;
  color: string;
  crosstableColor: string | null;
  created_by: string;
  complex: string;
  crosstable_name: {
    [key: string]: string;
  };
  crosstableName: {
    [key: string]: string;
  };
  id: number;
  module_name: {
    [key: string]: string;
  };
  moduleName: {
    [key: string]: string;
  };
  name: {
    [key: string]: string;
  };
  show_in_module: string;
  temporary_type: TypeOfStatus|null;
  update_time_interval: number|null;
}
