import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  option: {
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    width: '100%',
    height: '64px',
    padding: '12px',
    display: 'flex',
    border: '1px solid #E9E6EF',
    borderRadius: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 0px 5px -1px black',
    },
  },
  optionIcon: {
    width: '24px',
    height: '24px',
    minWidth: '24px',
  },
  optionTitle: {
    color: '#19191B',
    fontSize: '14px',
    fontWeight: '400',
    marginRight: 'auto',
  },
  optionGearWrap: {
    display: 'flex',
    gap: '8px',
    '& > img': {
      width: '24px',
      height: '24px',
    },
  },
}));
