/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { UploadButton } from 'src/components/UploadButton';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IDeveloper, IDevelopmentSpecificationProp, IFileUri } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import {
  postDeveloperData, putDeveloperData, uploadFile,
} from 'src/api';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useLanguage } from 'src/hooks/app/use-language';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { DeveloperPropertyList } from 'src/components/profileComponents/Developers/components/DeveloperPropertyList';
import { DeveloperPropertyType } from 'src/components/profileComponents/Developers/components/types';
import { Add } from '@mui/icons-material';
import { RandomInteger } from 'src/helpers/randomInteger';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { useStyles } from '../styles';

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  refreshDevelopers: Function;
  developer?: IDeveloper|null;
}

const schema = yup.object().shape({
  name: yup.string().required(translations.requiredField),
  language: yup.string().required(translations.requiredField),
});

export const DevModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, developer, refreshDevelopers,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const [icon, setIcon] = useState<IFileUri | undefined>(developer?.logoFileUri ? developer.logoFileUri : undefined);
  const [tabValue, setTabValue] = useState<string>('1');
  const [title, setTitle] = useState<string>('');
  const [optionsList, setOptionsList] = useState<{[key: string]: IDevelopmentSpecificationProp[]}>({});
  const { languagesCodesOptions: allLanguagesOptions, getLanguagesList } = useLanguage();
  const [developerPrimaryLanguage, setDeveloperPrimaryLanguage] = useState<IDevelopmentSpecificationPropChange<string>>({ label: 'en', value: 'en' });
  const [developerSecondaryLanguages, setDeveloperSecondaryLanguages] = useState<IDevelopmentSpecificationPropChange<string>[]>([]);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const {
    register, handleSubmit, formState, setValue, getValues, reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    getLanguagesList();
    if (developer) {
      const formData = {
        [DeveloperPropertyType.purchaseTerms]: developer?.developmentSpecification.purchaseTerms,
        [DeveloperPropertyType.propertyClasses]: developer?.developmentSpecification.propertyClasses,
        [DeveloperPropertyType.salesStatuses]: developer?.developmentSpecification.salesStatuses,
        [DeveloperPropertyType.constructionPhases]: developer?.developmentSpecification.constructionPhases,
        [DeveloperPropertyType.completionDates]: developer?.developmentSpecification.completionDates,
      };
      setOptionsList(formData);

      setIcon(developer.logoFileUri || undefined);
      setTitle(developer.name[developer.language]);
      setDeveloperPrimaryLanguage({ label: developer.language, value: developer.language });
      setDeveloperSecondaryLanguages(
        developer.secondaryLanguages ? developer.secondaryLanguages.map((item) => ({ label: item, value: item })) : [],
      );
      reset({
        name: developer.name[developer.language],
        language: developer.language,
        secondaryLanguages: developer.secondaryLanguages,
        developmentSpecification: formData,
      });
    } else {
      reset({
        language: developerPrimaryLanguage,
      });
    }
  }, [developer]);

  const handleUploadIcon = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('logoUri', event.target.files[0]);

      uploadFile(jwt, formData).then((res: {data: IFileUri[]}) => {
        setIcon(res.data[0]);
        setValue('logoFileUri', res.data[0]);
      });
    }
  };

  const close = () => handleChangeOpen(false);

  const onSubmit = async (formData: any) => {
    let res;
    const saveData = {
      name: { [developerPrimaryLanguage.value]: formData.name },
      language: developerPrimaryLanguage.value,
      secondaryLanguages: developerSecondaryLanguages.map((language) => language.value),
      logoFileUri: formData.logoFileUri,
      developmentSpecification: { ...developer?.developmentSpecification },
    };
    if (formData.developmentSpecification) {
      Object.keys(formData.developmentSpecification).forEach((key) => {
        if (formData.developmentSpecification[key]) {
          // @ts-ignore
          saveData.developmentSpecification[key] = formData.developmentSpecification[key];
        }
      });
    }
    if (developer) {
      res = await putDeveloperData(jwt, developer.id, saveData);
    } else {
      res = await postDeveloperData(jwt, saveData);
    }
    if (res.ok) {
      close();
      refreshDevelopers();
    } else {
      setError(res.data['hydra:description']);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    reset(getValues());
  };

  const developerPropertyChange = (data: IDevelopmentSpecificationProp[], prop: string) => {
    setValue(prop, data);
  };

  const addNew = (type: DeveloperPropertyType) => {
    const value = getValues(`developmentSpecification.${type}`) || [];
    const transLang = developer?.language || developerPrimaryLanguage.value;
    if (transLang) {
      let newField: any = { id: RandomInteger(), name: { [transLang]: '' }, sortOrder: value.length };
      if (type === DeveloperPropertyType.completionDates) {
        newField = {
          id: RandomInteger(), name: { [transLang]: '' }, prefix: { [transLang]: '' }, suffix: { [transLang]: '' }, sortOrder: value.length,
        };
      }
      const newValue = [...value, newField];
      setOptionsList({ ...optionsList, [type]: newValue });
      setValue(`developmentSpecification.${type}`, newValue);
    }
  };

  const addNewBtn = (type: DeveloperPropertyType) => (
    <ButtonApp
      variant="outlined"
      className={styles.addBtn}
      startIcon={<Add />}
      onClick={() => addNew(type)}
    >
      Add new
    </ButtonApp>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="950px" maxHeight="90vh" overflow="auto" py={10} px={11}>
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {developer ? translations.developerEditing : translations.addDeveloper}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">

              <Box sx={{ width: '100%', typography: 'body1' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Main information" value="1" />
                        <Tab label="Purchase terms" value="2" disabled={!developerPrimaryLanguage.value?.length} />
                        <Tab label="Property class" value="3" disabled={!developerPrimaryLanguage.value?.length} />
                        <Tab label="Sales status" value="4" disabled={!developerPrimaryLanguage.value?.length} />
                        <Tab label="Construction phase" value="5" disabled={!developerPrimaryLanguage.value?.length} />
                        <Tab label="Completion date" value="6" disabled={!developerPrimaryLanguage.value?.length} />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box flex="1" display="flex" gap="36px">
                        <Box mb={6} display="flex" flexDirection="column">
                          { translations.logo }
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="384px"
                            height="308px"
                            mt={2}
                            border={`1px solid ${borderColor}`}
                            borderRadius="3px"
                            style={{
                              backgroundColor: '#FDFDFD',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundImage: `url(${icon?.fileUrl || ''})`,
                            }}
                          >
                            <input
                              type="hidden"
                              {...register('logoFileUri')}
                              name="logoFileUri"
                              value={icon?.fileUrl}
                            />
                            <label htmlFor="devIcon">
                              <input
                                accept="image/*,.jpg,.jpeg,.png,.svg,.webp"
                                style={{
                                  display: 'none',
                                }}
                                id="devIcon"
                                type="file"
                                onChange={handleUploadIcon}
                              />
                              <UploadButton isBig withoutBorder />
                            </label>

                          </Box>
                        </Box>
                        <Box flex="1" display="flex" flexDirection="column">
                          <Box mb={6} display="flex" flexDirection="column">
                            { translations.name }
                            <Box width="260px" mt={2}>
                              <TextField
                                id="name"
                                variant="outlined"
                                name="name"
                                inputRef={register}
                                defaultValue={title}
                                onChange={(e) => {
                                  setValue('name', e.target.value);
                                  setTitle(e.target.value);
                                }}
                                {...register('name')}
                              />
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <BoxFormAutocomplete
                              label={translations.primaryLanguage}
                              name="language"
                              options={allLanguagesOptions}
                              value={developerPrimaryLanguage}
                              onChange={(value: IDevelopmentSpecificationPropChange<string>) => {
                                setValue('language', value.value);
                                setDeveloperPrimaryLanguage(value);
                              }}
                              {...register('language')}
                            />
                          </Box>
                          <Box display="flex" flexDirection="column">
                            <BoxFormAutocomplete
                              label={translations.secondaryLanguages}
                              name="secondaryLanguages"
                              options={allLanguagesOptions}
                              value={developerSecondaryLanguages}
                              onChange={(value: IDevelopmentSpecificationPropChange<string>[]) => {
                                setValue('secondaryLanguages', value.map((item) => item.value));
                                setDeveloperSecondaryLanguages(value);
                              }}
                              {...register('secondaryLanguages')}
                            />
                          </Box>
                        </Box>
                      </Box>

                    </TabPanel>
                    <TabPanel value="2">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.purchaseTerms)) }
                      </Box>
                      <DeveloperPropertyList
                        list={optionsList[DeveloperPropertyType.purchaseTerms] || []}
                        lang={developerPrimaryLanguage.value}
                        onChange={(data) => developerPropertyChange(data, `developmentSpecification.${DeveloperPropertyType.purchaseTerms}`)}
                        {...register(`developmentSpecification.${DeveloperPropertyType.purchaseTerms}`)}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.propertyClasses)) }
                      </Box>
                      <DeveloperPropertyList
                        list={optionsList[DeveloperPropertyType.propertyClasses] || []}
                        lang={developerPrimaryLanguage.value}
                        onChange={(data) => developerPropertyChange(data, `developmentSpecification.${DeveloperPropertyType.propertyClasses}`)}
                        {...register(`developmentSpecification.${DeveloperPropertyType.propertyClasses}`)}
                      />
                    </TabPanel>
                    <TabPanel value="4">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.salesStatuses)) }
                      </Box>
                      <DeveloperPropertyList
                        list={optionsList[DeveloperPropertyType.salesStatuses] || []}
                        lang={developerPrimaryLanguage.value}
                        onChange={(data) => developerPropertyChange(data, `developmentSpecification.${DeveloperPropertyType.salesStatuses}`)}
                        {...register(`developmentSpecification.${DeveloperPropertyType.salesStatuses}`)}
                      />
                    </TabPanel>
                    <TabPanel value="5">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.constructionPhases)) }
                      </Box>
                      <DeveloperPropertyList
                        list={optionsList[DeveloperPropertyType.constructionPhases] || []}
                        lang={developerPrimaryLanguage.value}
                        onChange={(data) => developerPropertyChange(data, `developmentSpecification.${DeveloperPropertyType.constructionPhases}`)}
                        {...register(`developmentSpecification.${DeveloperPropertyType.constructionPhases}`)}
                      />
                    </TabPanel>
                    <TabPanel value="6">
                      <Box className={styles.addBtnWrapper}>
                        { (addNewBtn(DeveloperPropertyType.completionDates)) }
                      </Box>
                      <DeveloperPropertyList
                        list={optionsList[DeveloperPropertyType.completionDates] || []}
                        lang={developerPrimaryLanguage.value}
                        onChange={(data) => developerPropertyChange(data, `developmentSpecification.${DeveloperPropertyType.completionDates}`)}
                        {...register(`developmentSpecification.${DeveloperPropertyType.completionDates}`)}
                      />
                    </TabPanel>
                    <Box display="flex" justifyContent="flex-end" gap="20px">
                      <ButtonApp
                        onClick={close}
                        text={translations.cancel}
                        color="primary"
                        variant="outlined"
                        type="button"
                        className={styles.btn}
                      />
                      <ButtonApp
                        text={translations.saveChanges}
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={styles.btn}
                        disabled={!formState.isValid}
                      />
                    </Box>
                  </TabContext>
                </form>
              </Box>
              <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                  { error }
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
