/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  useRouteMatch, Link, useLocation, useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { requestSelectors } from 'src/store/requests/selectors';
import { Actions } from 'src/store/requests/user/actions';
import { appIcon } from 'src/assets';
import { useGetAuthData, useIsDevMode } from 'src/hooks';
import { linkList } from 'src/containers/Profile/routeList';
import { translations } from 'src/constants/translations';
import { TypeOfRole } from 'src/typings/user';
import { useStyles } from './styles';

const userRoles = {
  [TypeOfRole.admin]: translations.admin,
  [TypeOfRole.manager]: translations.manager,
  [TypeOfRole.superadmin]: translations.superadmin,
  [TypeOfRole.supervisor]: translations.supervisor,

};

export const Sidebar = () => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { removeJWT } = useGetAuthData();
  const user = useSelector(requestSelectors.user.getUserData);
  const { isDevMode } = useIsDevMode();
  const logout = () => {
    removeJWT();
    history.replace('/');
    dispatch(Actions.getUserDataSuccess({}));
  };
  return (
    <Box pl={4} pr={4} pt={5} width={217} className={styles.wrapper}>
      <Box mb={20}>
        <Link to="/">
          <img src={appIcon.bigLogo.default} alt="Flatshow logo" />
        </Link>
      </Box>

      <Box mb={1} className={styles.avatar}>
        <img className={styles.avatarImg} src={user?.iconUri || appIcon.avatar.default} alt="user avatar" />
      </Box>
      <Box color="text.primary" fontWeight={400} fontSize={18} lineHeight="21px" mb={1}>
        {user?.username}
      </Box>
      <Box color="text.secondary" mb={8} fontSize={18} lineHeight="21px">
        {user && user?.role && userRoles[user?.role?.['@id']]}
      </Box>
      <Box mr={-4} ml={-4}>
        {linkList.map((item) => (((item.disabledForSupervisor && user?.role?.['@id'] === TypeOfRole.supervisor)
          || (item.disabledForManager && user?.role?.['@id'] === TypeOfRole.manager) || (item.disabledForProd && item.disabledForProd === !isDevMode))
          ? null : (
            item.isAbsoluteLink ? (
              <a
                className={`${styles.sidebarLink} ${pathname.includes(item.path) && 'active'}`}
                key={item.path}
                href={`${window.location.origin}/${item.path}`}
              >
                {item.name}
              </a>
            ) : (
              <Link
                className={`${styles.sidebarLink} ${pathname.includes(item.path) && 'active'}`}
                key={item.path}
                to={item.isAbsoluteLink ? `/${item.path}` : `${path}/${item.path}`}
                replace={!!item.isAbsoluteLink}
              >
                {item.name}
              </Link>
            )

          )))}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={`${styles.sidebarLink}`}
          style={{
            cursor: 'pointer',
          }}
          onClick={logout}
        >
          {translations.logout}
        </div>
      </Box>
    </Box>
  );
};
