import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getHouses } from 'src/store/crosstable/selectors';
import { IHouses, IHouse } from 'src/store/crosstable/reducer';
import { Actions } from 'src/store/crosstable/actions';
import { useGetAuthData } from 'src/hooks';

import { getHouseById, getHousesByComplex } from 'src/api';
import { useQuery } from '@tanstack/react-query';

export const useGetHouses = (id: string|undefined) => {
  const [houses, setHouses] = useState<IHouse[]|[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const savedHouses: IHouses|null = useSelector(getHouses);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchHouse = () => {
    if (id) {
      setIsLoading(true);
      getHousesByComplex(jwt, id)
        .then((res: any) => {
          setHouses(res.data['hydra:member']);
          dispatch(Actions.setHouses({
            complexId: id,
            data: res.data['hydra:member'],
          }));
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    if (id && (!savedHouses || Number(id) !== Number(savedHouses.complexId))) {
      setIsLoading(true);
      getHousesByComplex(jwt, id)
        .then((res: any) => {
          if (!cleanupFunction) {
            setHouses(res.data['hydra:member']);
            dispatch(Actions.setHouses({
              complexId: id,
              data: res.data['hydra:member'],
            }));
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (savedHouses) {
      setHouses(savedHouses.data);
    }

    return () => {
      cleanupFunction = true;
    };
  }, [jwt, id]);

  return {
    houses,
    isLoading,
    fetchHouse,
  };
};

export function useGetHouseById(id: string|undefined) {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  return useQuery({
    queryKey: ['house'],
    queryFn: async (): Promise<IHouse|null> => {
      if (!id) return null;
      const { data } = await getHouseById(jwt, id);
      return data;
    },
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
}
