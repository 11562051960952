import { ApiUrl } from 'src/constants';
import { httpService } from 'src/services';

export function getComplexData(id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}/${id}`, {}, {});
}

export function getComplexByDev(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?developers=${id}&pagination=false`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexWithoutDev(token: string = '') {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?exists[developers]=false&pagination=false&showInCrosstable=true`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function getComplexByAmoPipeline(token: string = '', id: string) {
  return httpService.get(`${ApiUrl.getApiUrl().getComplexes}?amo_pipeline.amo_id=${id}&pagination=false`, {}, {
    Authorization: `Bearer ${token}`,
  });
}

export function postComplexData(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getComplexes}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function patchComplexData(token: string = '', id: string|number, data: any) {
  return httpService.patch(`${ApiUrl.getApiUrl().getComplexes}/${id}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function putComplexData(token: string = '', id: string|number, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getComplexes}/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}
