import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { pathList } from 'src/containers/Profile/routeList';
import { useMarketplaces } from 'src/hooks/marketplace';
import {
  marketplaceSetUpOptions,
} from 'src/components/profileComponents/Marketplace/components/MarketplaceSettings/constants';
import { appIcon } from 'src/assets';
import { Loader } from 'src/components/Loader';
import { useStyles } from './styles';

export const MarketplaceSettings: React.FC = () => {
  const { id }: { id: string } = useParams();
  const { getMarketplace } = useMarketplaces();
  const styles = useStyles({});
  const location = useLocation();

  const marketplace = getMarketplace(Number(id));
  const options = marketplaceSetUpOptions;

  const crumbs: ICrumbs[] = [
    {
      name: translations.marketplace,
      url: `/profile/${pathList.marketplace}`,
    },
    {
      name: `${marketplace?.name[marketplace?.language]} set-up`,
    },
  ];

  if (!marketplace) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`${translations.marketplace} ${marketplace?.name[marketplace?.language]}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box mb={4} display="flex" gap="16px">
        {options && options.map((option) => (
          <Link
            key={option.title}
            to={`${location.pathname}${option?.link}`}
            className={styles.option}
          >
            <Box
              className={styles.optionIcon}
              sx={{
                backgroundImage: `url("${option.icon}")`,
              }}
            />
            <Box className={styles.optionTitle}>
              {option.title}
            </Box>
            <Box className={styles.optionGearWrap}>
              <img src={appIcon.iconGearBlue.default} alt="gear blue" />
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
