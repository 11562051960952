/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetComplex } from 'src/hooks';
import { borderColor } from 'src/constants/styles';
import { ComplexImages } from './components/ComplexImages';
import { ManagersList } from './components/ManagersList';
import { ComplexStatuses } from './components/ComplexStatuses';

export const EditComplex = () => {
  const { id }: { id: string } = useParams();
  const { complex, fetchComplex } = useGetComplex(id);

  if (!complex) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const {
    language,
    title,
  } = complex;

  const crumbs: ICrumbs[] = [
    {
      name: translations.complexesLink,
      url: `/profile/${pathList.complexes}`,
    },
    {
      name: `${translations.settings} ${title[language]}`,
    },
  ];

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        {`${translations.settings} ${title[language]}`}
      </Box>
      <Box mb={5}>
        <Breadcrumbs crumbs={crumbs} />
      </Box>
      <Box display="flex" mb={4} alignItems="flex-start" flexWrap="nowrap">
        <ComplexImages
          complex={complex}
          fetchComplex={fetchComplex}
        />
        <Box pl={6} width="calc(100% - 440px)">
          <ComplexStatuses complex={complex} />
        </Box>

      </Box>
      <Box
        mb={4}
        pt={4}
        pb={4}
        display="flex"
        justifyContent="flex-start"
        borderTop={`1px solid ${borderColor}`}
        borderBottom={`1px solid ${borderColor}`}
      >
        <Link to={`/profile/${pathList.complexes}/${id}/storage`}>
          <ButtonApp
            text={translations.goToStorage}
            color="secondary"
            variant="outlined"
            size="large"
            style={{
              padding: '15px 30px',
              fontSize: '18px',
            }}
          />
        </Link>
      </Box>
      <ManagersList {...complex} />
    </Box>
  );
};
