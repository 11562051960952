import React from 'react';

import { ButtonApp } from 'src/components/Button';
import { locals } from 'src/constants/locals';
import { ModalBookNow } from 'src/components/modals/BookNow';
import { useTranslations } from 'src/hooks/translataions';
import { useBookNowState } from 'src/components/modals/BookNow/hooks';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useBuildType, useGetComplex } from 'src/hooks';
import { CreatePdf } from 'src/components/ApartmentPageV2/TabComponent/components/CreatePdf';
import { useSelector } from 'react-redux';
import { getPlacementViewConfig } from 'src/store/placementViewConfig/selector';
import { UtilityButtonTypeEnum } from 'src/typings/viewConfig';
import { getComplexId } from 'src/store/app/selectors';
import { useStyles } from '../CompareItems/styles';

interface ICompareItemBookNow {
  item: IFilterAllData;
  pdfLoading?: (loading: boolean) => void;
  pdfDisabled: boolean;
}

export const CompareItemBookNow: React.FC<ICompareItemBookNow> = ({
  item,
  pdfLoading,
  pdfDisabled,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { isOpen, handleToggleState } = useBookNowState();
  const { isCrossTable } = useBuildType();
  const placementViewConfig = useSelector(getPlacementViewConfig);
  const complexId = useSelector(getComplexId);
  const { complex } = useGetComplex(complexId ? String(complexId) : null, false);

  const onClickHandle = () => {
    if (placementViewConfig?.applicationProperties) {
      const foundProp = placementViewConfig.placementProperties
        .find((prop) => prop.systemName === placementViewConfig.headerSection.unitPageRedirect.placementProperty?.systemName);
      // @ts-ignore
      if (foundProp?.oldSystemName && item?.[foundProp.oldSystemName]) {
        // @ts-ignore
        window.open(item?.[foundProp.oldSystemName], '_blank');
      }
    }
  };

  return (
    <div key={item.id} className={`${styles.compareValue} ${styles.wrapperCompareType}`}>
      {!isCrossTable
        && placementViewConfig?.headerSection?.unitPageRedirect.isHidden
        && !placementViewConfig?.headerSection?.callToAction.isHidden && (
        <ButtonApp
          isFullSize
          isCapitalize
          withBorder
          text={getTranslations(locals.appTranslations.appBookNow)}
          color="secondary"
          onClick={handleToggleState}
        />
      )}
      {!isCrossTable
        && placementViewConfig?.headerSection?.callToAction.isHidden
        && !placementViewConfig?.headerSection?.unitPageRedirect.isHidden && (
        <ButtonApp
          isFullSize
          isCapitalize
          withBorder
          text={placementViewConfig?.headerSection?.unitPageRedirect.name?.translates?.[placementViewConfig?.lang]
            || placementViewConfig?.headerSection?.unitPageRedirect.name?.systemName || 'More details'}
          color="secondary"
          onClick={onClickHandle}
        />
      )}
      {isCrossTable && placementViewConfig?.headerSection?.utilityButtons.map((button) => {
        if (button.applicationProperty.systemName === UtilityButtonTypeEnum.downloadPDF && !button.isHidden) {
          return (
            <CreatePdf
              key={button.id}
              title={button.applicationProperty.translates?.[placementViewConfig.lang] || 'Download PDF'}
              id={item[ListOfKeysFilterAllData.id]}
              placement={item}
              disabled={pdfDisabled}
              isLoading={pdfLoading}
              circularProgressSize={14}
            />
          );
        }
        return (<div key={button.id} />);
      })}
      {isOpen && (
        <ModalBookNow
          isOpen={isOpen}
          onClose={handleToggleState}
          complex={complex}
        />
      )}
    </div>
  );
};
