import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { greyColor, fontBlackColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
  },
  greyButton: {
    borderColor: greyColor,
    color: fontBlackColor,
    textTransform: 'none',
    '&:hover': {
      // background: 'white',
      borderColor: greyColor,
    },
  },
  button: {
    fontSize: '18px',
  },
  modalWindow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    outline: '0',
  },
  btn: {
    padding: '15px 30px',
    fontSize: '18px',
  },
  closeBtn: {
    cursor: 'pointer',
    opacity: '0.5',
    '&:hover': {
      opacity: '1',
    },
  },
  uploadWrapper: {
    width: '100%',
  },
  upload: {
    '& .MuiInputBase-root': {
      height: '208px',
      cursor: 'pointer',
      '& input': {
        height: '208px',
      },
    },
  },
  uploadContent: {
    position: 'relative',
    '&:hover': {
      '& .uploadIcon': {
        opacity: '1!important',
      },
    },
  },
  uploadIconWrapper: {
    position: 'absolute',
    right: '45%',
    bottom: '40%',
    display: 'flex',
    gap: '5px',
  },
  uploadIcon: {
    cursor: 'pointer',
    color: '#6C59E6',
    borderRadius: '50%',
    backgroundColor: 'white',
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
