import React from 'react';
import { translations } from 'src/constants/translations';
import { IComplex } from 'src/typings/complexes';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ButtonApp } from 'src/components/Button';
import {
  ILocationsDefaultValues,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { useStyles } from './styles';

export const LocationDetails: React.FC<{
  register: any,
  defaultValues: ILocationsDefaultValues,
  complex: IComplex | undefined,
  onChange?: (data: string, prop: string) => void
}> = ({
  register, defaultValues, onChange,
}) => {
  const styles = useStyles();
  const _onChange = (value: string, prop: string) => {
    if (onChange) {
      onChange(value, prop);
    }
  };

  return (
    <>
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.latitude }
            <Box mt={2}>
              <TextField
                id="latitude"
                className={styles.textField}
                placeholder="00.0000"
                variant="outlined"
                name="location.latitude"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.latitude')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.longitude }
            <Box mt={2}>
              <TextField
                id="longitude"
                className={styles.textField}
                placeholder="000.0000"
                variant="outlined"
                name="location.longitude"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.longitude')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            <Box mt={6}>
              <ButtonApp
                text={translations.updateLocations}
                style={{ color: '#6C59E6', borderColor: '#6C59E6', opacity: '0.4' }}
                variant="outlined"
                type="button"
                disabled
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.countryAutoCalculated }
            <Box mt={2}>
              <TextField
                id="countryAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.countryAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.countryAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.countryManual }
            <Box mt={2}>
              <TextField
                id="countryManual"
                className={styles.textField}
                variant="outlined"
                name="location.countryManual"
                inputRef={register}
                defaultValue={defaultValues.countryManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.countryManual');
                }}
                {...register('location.countryManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.cityAutoCalculated }
            <Box mt={2}>
              <TextField
                id="cityAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.cityAutoCalculated"
                inputRef={register}
                defaultValue={defaultValues.cityAutoCalculated}
                disabled
                {...register('location.cityAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.cityManual }
            <Box mt={2}>
              <TextField
                id="cityManual"
                className={styles.textField}
                variant="outlined"
                name="location.cityManual"
                inputRef={register}
                defaultValue={defaultValues.cityManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.cityManual');
                }}
                {...register('location.cityManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.districtAutoCalculated }
            <Box mt={2}>
              <TextField
                id="districtAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.districtAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.districtAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.districtManual }
            <Box mt={2}>
              <TextField
                id="districtManual"
                className={styles.textField}
                variant="outlined"
                name="location.districtManual"
                inputRef={register}
                defaultValue={defaultValues.districtManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.districtManual');
                }}
                {...register('location.districtManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroStationAutoCalculated }
            <Box mt={2}>
              <TextField
                id="nearestMetroStationAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroStationAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.nearestMetroStationAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroStationManual }
            <Box mt={2}>
              <TextField
                id="nearestMetroStationManual"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroStationManual"
                inputRef={register}
                defaultValue={defaultValues.nearestMetroStationManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.nearestMetroStationManual');
                }}
                {...register('location.nearestMetroStationManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroBranchAutoCalculated }
            <Box mt={2}>
              <TextField
                id="nearestMetroBranchAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroBranchAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.nearestMetroBranchAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroBranchManual }
            <Box mt={2}>
              <TextField
                id="nearestMetroBranchManual"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroBranchManual"
                inputRef={register}
                defaultValue={defaultValues.nearestMetroBranchManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.nearestMetroBranchManual');
                }}
                {...register('location.nearestMetroBranchManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroWalkMinutesAutoCalculated }
            <Box mt={2}>
              <TextField
                id="nearestMetroWalkMinutesAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroWalkMinutesAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.nearestMetroWalkMinutesAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroWalkMinutesManual }
            <Box mt={2}>
              <TextField
                id="nearestMetroWalkMinutesManual"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroWalkMinutesManual"
                inputRef={register}
                defaultValue={defaultValues.nearestMetroWalkMinutesManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.nearestMetroWalkMinutesManual');
                }}
                {...register('location.nearestMetroWalkMinutesManual')}
              />
            </Box>
          </Box>
        </Box>
        <Box flex="1" display="flex" gap="40px">
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroDriveMinutesAutoCalculated }
            <Box mt={2}>
              <TextField
                id="nearestMetroDriveMinutesAutoCalculated"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroDriveMinutesAutoCalculated"
                inputRef={register}
                defaultValue=""
                disabled
                {...register('location.nearestMetroDriveMinutesAutoCalculated')}
              />
            </Box>
          </Box>
          <Box mb={6} display="flex" flexDirection="column" width="100%">
            { translations.nearestMetroDriveMinutesManual }
            <Box mt={2}>
              <TextField
                id="nearestMetroDriveMinutesManual"
                className={styles.textField}
                variant="outlined"
                name="location.nearestMetroDriveMinutesManual"
                inputRef={register}
                defaultValue={defaultValues.nearestMetroDriveMinutesManual}
                onChange={(e) => {
                  _onChange(e.target.value, 'location.nearestMetroDriveMinutesManual');
                }}
                {...register('location.nearestMetroDriveMinutesManual')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
