/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { appIcon } from 'src/assets';
import { useDebounce } from 'src/hooks';

export const SearchField: React.FC<{handleChange: (SearchTerm: string) => void, placeholder: string}> = ({ handleChange, placeholder = '' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    handleChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <TextField
      id="search field"
      variant="outlined"
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={appIcon.iconSearch.default} alt="icon search" />
          </InputAdornment>
        ),
      }}
    />
  );
};
