import { httpService } from 'src/services';
import { ApiUrl } from 'src/constants';
import { IMarketplace } from 'src/typings/marketplaces';
import { HydraResponse } from 'src/api/hydraResponse';

export function getMarketplaces(token: string = ''): Promise<HydraResponse<IMarketplace[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceById(token: string = '', id: number): Promise<HydraResponse<IMarketplace[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function postMarketplace(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplaces}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function putMarketplace(token: string = '', id: number, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}
