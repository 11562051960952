/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { IComplex } from 'src/typings/complexes';
import { useGetAuthData } from 'src/hooks';
import { borderColor, purpleColor } from 'src/constants/styles';
import { getComplexWithoutDev, patchDeveloperData } from 'src/api';
import { useStyles } from '../styles';

interface StatusModal {
  isOpen: boolean;
  handleChangeOpen: Function;
  setDeveloper: Function;
  id: string|number;
  complexes: IComplex[];
}

export const ChooseComplexModal: React.FC<StatusModal> = ({
  isOpen, handleChangeOpen, complexes = [], id, setDeveloper,
}) => {
  const styles = useStyles({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedComplexes, setSelectedComplexes] = useState<string[]>(complexes.map((item) => item['@id']));
  const [freeComplexes, setFreeComplexes] = useState<IComplex[]>([]);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  useEffect(() => {
    let cleanupFunction = false;
    setIsLoading(true);
    getComplexWithoutDev(jwt)
      .then((res: any) => {
        if (!cleanupFunction) {
          const { data } = res;
          setFreeComplexes(complexes.concat(data['hydra:member']));
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });

    return () => {
      cleanupFunction = true;
    };
  }, [jwt]);

  const onClickComplex = (complexId: string, selectedIndex: number) => {
    if (selectedIndex !== -1) {
      const temp = [...selectedComplexes];
      temp.splice(selectedIndex, 1);
      setSelectedComplexes(temp);
    } else {
      const temp = [...selectedComplexes];
      temp.push(complexId);
      setSelectedComplexes(temp);
    }
  };

  const close = () => handleChangeOpen(false);

  const onSubmit = async () => {
    const res = await patchDeveloperData(jwt, id, {
      complexes: selectedComplexes,
    });
    if (res.ok) {
      const { data } = res;
      close();
      setDeveloper(data);
    } else {
      setError(res.data['hydra:description']);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box
            width="700px"
            maxHeight="90vh"
            py={10}
            px={11}
            display="flex"
            flexDirection="column"
          >
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {translations.addComplex}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box
              mb={4}
              fontSize="12px"
              maxHeight="100%"
              overflow="hidden auto"
              pr={3}
            >
              <Grid container spacing={3}>
                {freeComplexes.map((complex) => {
                  const selectedIndex = selectedComplexes.indexOf(complex['@id']);
                  const isSelected = selectedIndex !== -1;
                  return (
                    <Grid item xs={3} key={complex['@id']}>
                      <Box
                        className={styles.developerHover}
                        p={3}
                        border={`1px solid ${isSelected ? purpleColor : borderColor}`}
                        borderRadius="3px"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                        height="100%"
                        position="relative"
                        onClick={() => onClickComplex(complex['@id'], selectedIndex)}
                      >
                        <Box
                          width="60px"
                          height="60px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          overflow="hidden"
                          mb={2}
                        >
                          <img
                            style={{
                              maxHeight: '100%',
                              maxWidth: '100%',
                            }}
                            src={complex.logoFileUri?.fileUrl || ''}
                            alt={complex.title[complex.language]}
                          />
                        </Box>
                        {complex.title[complex.language]}
                        {isSelected && (
                          <Box display="inline-flex" position="absolute" bottom="0" right="0">
                            <CheckCircleIcon htmlColor={purpleColor} />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-end" gap="20px">
              <ButtonApp
                onClick={close}
                text={translations.cancel}
                color="primary"
                variant="outlined"
                type="button"
                className={styles.btn}
              />
              <ButtonApp
                text={translations.saveChanges}
                color="primary"
                variant="contained"
                type="submit"
                className={styles.btn}
                onClick={onSubmit}
              />
            </Box>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
              <Alert onClose={() => setError('')} elevation={6} variant="filled" severity="error">
                {error}
              </Alert>
            </Snackbar>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
