import { appIcon } from 'src/assets';

export enum marketplaceSetUpOptionsEnum {
  uiComponentsDisplay = 'uiComponentsDisplay',
  filters = 'filters',
  projectsTile = 'projectsTile',
  unitsTile = 'unitsTile',
  translates = 'translates',
}

interface marketplaceSetUpOption {
  type: marketplaceSetUpOptionsEnum,
  title: string,
  icon: string,
  link: string,
}

export const marketplaceSetUpOptions: marketplaceSetUpOption[] = [
  {
    type: marketplaceSetUpOptionsEnum.uiComponentsDisplay,
    title: 'UI components display',
    icon: appIcon.iconUiDisplay.default,
    link: '/ui-components',
  },
  {
    type: marketplaceSetUpOptionsEnum.filters,
    title: 'Filters',
    icon: appIcon.iconFilters.default,
    link: '/filters',
  },
  {
    type: marketplaceSetUpOptionsEnum.projectsTile,
    title: 'Projects tile',
    icon: appIcon.iconCategory.default,
    link: '/filters',
  },
  {
    type: marketplaceSetUpOptionsEnum.unitsTile,
    title: 'Units tile',
    icon: appIcon.iconCategory.default,
    link: '/units',
  },
  // {
  //   type: marketplaceSetUpOptionsEnum.translates,
  //   title: 'Components naming and translates',
  //   icon: appIcon.iconTranslates.default,
  //   link: '/translates',
  // },
];
