import React from 'react';

export const SvgTrash: React.FC<{width?: string, height?: string}> = (
  { width = 24, height = 24 },
) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id={`${width} * ${height}`} clipPath="url(#clip0_52_793)">
        <path id="Vector" d="M3.3335 5.83331H16.6668" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M8.3335 9.16669V14.1667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M11.6665 9.16669V14.1667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path
          id="Vector_4"
          d="M4.1665 5.83331L4.99984 15.8333C4.99984 16.2753 5.17543 16.6993 5.48799 17.0118C5.80055 17.3244 6.22448 17.5 6.6665 17.5H13.3332C13.7752 17.5 14.1991 17.3244 14.5117 17.0118C14.8242 16.6993 14.9998 16.2753 14.9998 15.8333L15.8332 5.83331"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_52_793">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
